import { DeductionResponse, updateDeduction } from "@/api/deduction.tsx"
import { Card } from "@/components/ui/card.tsx"
import { Input } from "@/components/ui/input.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { toast } from "@/components/ui/use-toast.ts"
import { dateFormatter, displayFormattedDistributor, FULL_DATE_FORMATTER } from "@/utils"
import { useState } from "preact/hooks"
import { CategoryCell } from "../category_cell"
import { StatusSelect } from "../status_select.tsx"
import { StatusFlag } from "../status_state.tsx"

export function DeductionCard({ deduction, onUpdate }: { 
  deduction: DeductionResponse;
  onUpdate: (updated: DeductionResponse) => void;
}) {
    // Track which field is being edited
    const [editingField, setEditingField] = useState<string | null>(null)
    // Track temporary values while editing
    const [editValue, setEditValue] = useState("")

    const handleEdit = (key: string, value: string) => {
      setEditingField(key)
      setEditValue(value)
    }

    const handleSave = async (key: string) => {
      try {
        const payload = {
          [key]: editValue
        };
        
        const updatedDeduction = await updateDeduction(deduction.id, payload);
        onUpdate(updatedDeduction);
        setEditingField(null);
      } catch (error) {
        console.error('Failed to update deduction:', error);
        toast({
          title: "Failed to update deduction",
          description: "Please reach out to Joey or Kurt if the issue persists",
        });
      }
      toast({
        title: "Updated",
        description: `Successfully updated ${key.replace(/_/g, ' ')}`,
      });
    }

    const KEYS_TO_DISPLAY = [
      { key: "invoice_number", displayKey: "Invoice #", editable: false, width: "w-32" },
      {
        key: "invoice_date",
        displayKey: "Invoice Date",
        customRender: (value: string) => dateFormatter(value, FULL_DATE_FORMATTER),
        editable: false,
        width: "w-36",
      },
      { key: "check_number", displayKey: "Check #", editable: false, width: "w-32" },
      { key: "po_number", displayKey: "PO #", editable: true, width: "w-32" },
      { key: "dc", displayKey: "DC", editable: false, width: "w-24" },
      { key: "invoice_amount", displayKey: "Invoice Amount", editable: false, width: "w-36" },
      {
        key: "source",
        displayKey: "Customer",
        customRender: (sourceValue: string) => displayFormattedDistributor(sourceValue, deduction.original_source),
        editable: false,
        width: "w-36",
      },
      { 
        key: "category", 
        displayKey: "Category", 
        editable: false, 
        width: "w-32",
        customRender: () => <CategoryCell deduction={deduction} className="w-full" />
      },
      { key: "description", displayKey: "Distributor Description", editable: false, width: "w-40" },
      { key: "retailer_name", displayKey: "Retailer", editable: true, width: "w-40" },
      {
        key: "execution_date",
        displayKey: "Execution Date",
        editable: true,
        width: "w-36",
        customRender: (value: string) => {
          if (editingField === "execution_date") {
            return (
              <Input
                type="date"
                className="h-8 w-36"
                value={editValue || (value ? new Date(value).toISOString().split('T')[0] : '')}
                onChange={(e: Event) => {
                  const target = e.target as HTMLInputElement
                  setEditValue(target.value)
                }}
                onBlur={() => handleSave("execution_date")}
                autoFocus
              />
            )
          }
          return value ? dateFormatter(value, FULL_DATE_FORMATTER) : "-"
        },
      },
      {
        key: "status_value",
        displayKey: "Status",
        customRender: () => (
          <StatusSelect deduction={deduction} onStatusChange={() => StatusFlag.set(flag => !flag)} />
        ),
        editable: false,
        width: "w-36",
      },
  
      // todo: notes, backup deduction (product, promo type / dates, win confidence)
    ]
  
    return (
      <Card className="overflow-x-auto py-2 bg-white mt-6">
        <div className="flex justify-between items-stretch">
          {KEYS_TO_DISPLAY.map(({ key, displayKey, customRender, editable, width }, index) => (
            <>
              <div className={`flex flex-col mx-4 ${width}`}>
                <div className="text-sm text-gray-500 w-full text-center truncate h-5">
                  {displayKey}
                </div>
                <div 
                  className={`text-sm mt-2 w-full text-center min-h-[2rem] flex items-center justify-center
                    ${editable ? 'cursor-pointer hover:bg-gray-100 px-2 py-1 rounded' : ''}`}
                  onClick={() => editable && handleEdit(key, deduction[key as keyof DeductionResponse] as string)}
                >
                  {editingField === key && key !== "execution_date" ? (
                    <Input
                      className="h-8 w-full"
                      value={editValue}
                      onChange={(e: Event) => {
                        const target = e.target as HTMLInputElement
                        setEditValue(target.value)
                      }}
                      onBlur={() => handleSave(key)}
                      autoFocus
                    />
                  ) : (
                    <div className="break-words">
                      {customRender
                        ? customRender((deduction[key as keyof DeductionResponse] as string) ?? "")
                        : deduction[key as keyof DeductionResponse] ?? "-"}
                    </div>
                  )}
                </div>
              </div>
              {index < KEYS_TO_DISPLAY.length - 1 && (
                <Separator orientation="vertical" className="h-full self-stretch" />
              )}
            </>
          ))}
        </div>
      </Card>
    )
  }