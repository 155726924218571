import { QueryData, api_fetch } from "@/api"
import { CATEGORY_COLORS } from "@/deductions/category_breakdown"
import { toISODateString } from "@/utils"
import { addDays, format, parseISO, subDays } from "date-fns"
import { useEffect, useState } from "preact/hooks"
import { ChartData, MonthlyBreakdownChartBase, RawData } from "./monthly_breakdown_base"

interface DetailedRawData extends RawData {
  category_name: string
}

const colorMap: { [key: string]: string } = {
  amber: "#f59e0b90", // amber-500 with 20% opacity
  emerald: "#10b98190", // emerald-500 with 20% opacity
  fuchsia: "#d946ef90", // fuchsia-500 with 20% opacity
  sky: "#0ea5e990", // sky-500 with 20% opacity
  rose: "#f43f5e90", // rose-500 with 20% opacity
  slate: "#64748b90", // slate-500 with 20% opacity
  teal: "#14b8a690", // teal-500 with 20% opacity
  lime: "#84cc1690", // lime-500 with 20% opacity
  indigo: "#6366f190", // indigo-500 with 20% opacity
}

export function DetailedMonthlyBreakdownChart() {
  const [rawData, setRawData] = useState<DetailedRawData[]>([])
  const [chartData, setChartData] = useState<ChartData[]>([])
  const [categories, setCategories] = useState<string[]>([])

  useEffect(() => {
    async function fetchData() {
      try {
        const endDate = new Date()
        const startDate = subDays(new Date(), 365)
        let res = await api_fetch<QueryData>(`/deductions/query`, {
          params: {
            query_name: "detailed_monthly_breakdown",
            start_date: toISODateString(startDate),
            end_date: toISODateString(endDate),
          },
        })

        if (!res.ok) {
          throw new Error("Failed to fetch data")
        }

        const headerIndex = res.value.data.headers.reduce(
          (acc, header, index) => {
            acc[header] = index
            return acc
          },
          {} as { [key: string]: number },
        )

        const data = res.value.data.rows.map(row => {
          const parsedDate = parseISO(row[headerIndex.month] as string)
          const correctedDate = addDays(parsedDate, 1) // Adjust for off-by-one error
          return {
            month: format(correctedDate, "MMM yyyy"),
            category_name: row[headerIndex.category_name] as string,
            total_deductions: Number(row[headerIndex.total_deductions]),
          }
        })

        setRawData(data)
        setCategories([...new Set(data.map(item => item.category_name))])
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    function processData() {
      const grouped: { [key: string]: ChartData } = {}

      rawData.forEach(item => {
        if (!grouped[item.month]) {
          grouped[item.month] = { month: item.month }
        }
        grouped[item.month][item.category_name] = item.total_deductions
      })

      setChartData(Object.values(grouped))
    }

    processData()
  }, [rawData])

  const fallbackColors = [
    "amber",
    "emerald",
    "fuchsia",
    "sky",
    "rose",
    "slate",
    "teal",
    "lime",
    "indigo",
  ]

  const chartConfig = categories.reduce((config, category, index) => {
    const colorName =
      CATEGORY_COLORS[category as keyof typeof CATEGORY_COLORS] ||
      fallbackColors[index % fallbackColors.length]
    const color = colorMap[colorName] || "#64748b90" // Default to slate
    config[category] = {
      label: category,
      color: color,
    }
    return config
  }, {} as any)

  return (
    <MonthlyBreakdownChartBase
      title="Monthly Deductions by Category"
      titleTooltip="Showing total deductions for each category over the last 12 months"
      chartData={chartData}
      chartConfig={chartConfig}
      dataKeys={categories}
    />
  )
}
