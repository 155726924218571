import { Skeleton } from "@/components/ui/skeleton"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { classes } from "@/utils"
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table"
import { ArrowDownIcon, ArrowUpIcon, ChevronsUpDown } from "lucide-react"
import { useMemo, useState } from "preact/compat"

interface AnalyticsTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  loading?: boolean
}

export function AnalyticsTable<TData, TValue>({
  columns,
  data,
  loading = false,
}: AnalyticsTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useMemo(
    () =>
      useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: {
          sorting,
        },
      }),
    [data, columns, sorting]
  )

  const getRowColor = (row: any) => {
    const deductionPercentage = row.getValue("deduction_percentage")
    return classes(
      "transition-colors",
      Math.abs(deductionPercentage) >= 0.5 ? "bg-red-100" : "",
      Math.abs(deductionPercentage) >= 0.25 && Math.abs(deductionPercentage) < 0.5 ? "bg-yellow-100" : "",
    )
  }

  return (
    <Table>
      <TableHeader className="bg-slate-50">
        {table.getHeaderGroups().map(headerGroup => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <TableHead 
                key={header.id}
                className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                onClick={header.column.getToggleSortingHandler()}
              >
                <div className="flex items-center gap-2">
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {{
                    asc: <ArrowUpIcon className="h-4 w-4" />,
                    desc: <ArrowDownIcon className="h-4 w-4" />,
                  }[header.column.getIsSorted() as string] ?? (
                    header.column.getCanSort() ? <ChevronsUpDown className="h-4 w-4" /> : null
                  )}
                </div>
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>
      {loading ? (
        <TableBody>
          <TableRow>
            {Array.from({ length: columns.length }).map((_, cellIndex) => (
              <TableCell key={cellIndex}>
                <Skeleton className="w-[100px] h-[20px] rounded-full bg-slate-500" />
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(row => (
              <TableRow 
                key={row.id}
                className={getRowColor(row)}
              >
                {row.getVisibleCells().map(cell => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      )}
    </Table>
  )
} 