import { Button } from "@/components/ui/button"
import { useEffect, useState } from "preact/compat"

type PDFViewerProps = {
  s3Uri: string
  fileName: string
}

export function PDFViewer({ s3Uri, fileName }: PDFViewerProps) {
  const [pdfUrl, setPdfUrl] = useState<string | null>(null)

  useEffect(() => {
    if (s3Uri.toLowerCase().endsWith(".csv")) {
      return
    }
    const url = `/api/_s3?s3_uri=${encodeURIComponent(s3Uri)}`
    setPdfUrl(url)
  }, [s3Uri])

  if (!pdfUrl) {
    let message: string | JSX.Element = "Loading PDF..."
    if (s3Uri.toLowerCase().endsWith(".csv")) {
      message = (
        <div>
          This is a CSV file, so there is no PDF viewer available.
          <Button asChild variant="tertiary" className="ml-2">
            <a href={`/api/_s3?s3_uri=${encodeURIComponent(s3Uri)}`} download>
              Download here
            </a>
          </Button>
        </div>
      )
    }
    return <div class="mt-4">{message}</div>
  }

  return (
    <div className="h-full">
      <h3 className="text-lg font-semibold mb-2">{fileName}</h3>
      <iframe
        src={`${pdfUrl}#toolbar=0`}
        className="w-full h-[calc(100vh-200px)]"
        title={fileName}
      />
    </div>
  )
}
