"use client"

import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  RowSelectionState,
  SortingState,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table"

import { rankItem } from "@tanstack/match-sorter-utils"

import { Button } from "@/components/ui/button.tsx"
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover.tsx"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx"
import { useEffect, useMemo, useState } from "preact/compat"

import { AccountingMatchPopover } from "@/accounting/match_popover.tsx"
import { api_fetch } from "@/api/client.tsx"
import { DeductionResponse, ReasonCode } from "@/api/deduction.tsx"
import { bulk_request_backup } from "@/api/dispute.tsx"
import { User } from "@/api/user.tsx"
import { UserState } from "@/auth/user.tsx"
import { Separator } from "@/components/ui/separator.tsx"
import { Skeleton } from "@/components/ui/skeleton.tsx"
import { toast } from "@/components/ui/use-toast.ts"
import { DataTableState, INITIAL_TABLE_STATE } from "@/deductions/table_state.tsx"
import { getStartEnd, TRANSACTION_TYPES, TransactionTypeFilter } from "@/global_filter"
import { useAsyncEffect } from "@/utils"
import { capitalCase } from "change-case"
import {
  Check,
  ChevronDown,
  CircleCheck,
  Columns2,
  Download,
  File,
  FileWarning,
  Loader2,
  Tag,
  X,
} from "lucide-react"
import { useLocation } from "wouter-preact"
import { z } from "zod"
import { StatusFlag } from "../status_state.tsx"
import { AssignmentForm } from "./assignment_form.tsx"
import { CATEGORY_FORM_SCHEMA, CategoryForm } from "./category_form.tsx"
import { SearchInput } from "./debounced_input"
import { PageControls } from "./page_controls.tsx"
import { buildCsv, collectDownloadData, convertBackupToCsv, downloadCsv, getTargetUrl } from "./util.tsx"

interface DataTableProps<TData, TValue> {
  loading: boolean
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}

// Extract shared normalization logic
const normalizeValue = (value: any, columnId: string): string => {
  if (!value) return ""
  const stringValue = value.toString()

  if (columnId.includes("date")) {
    // For ISO dates (YYYY-MM-DD), remove dashes and allow partial matches
    return stringValue.replace(/-/g, "")
  }
  if (columnId.includes("amount")) {
    // Convert to number and back to string to remove trailing zeros
    const numericValue = parseFloat(stringValue.replace(/[$,]/g, ""))
    return numericValue.toString()
  }
  return stringValue
}

// Simplify dateFilter to use the shared normalizeValue function
const dateFilter = (row: any, columnId: string, value: string) => {
  const cellValue = row.getValue(columnId)
  if (!cellValue) return false

  // Convert user input (MM/DD/Y...) to normalized format for matching
  let normalizedFilterValue = value.toString()
  if (normalizedFilterValue.match(/^\d{1,2}[-/]\d{1,2}([-/]\d{1,4})?$/)) {
    const [month, day, year] = normalizedFilterValue.split(/[-/]/)
    normalizedFilterValue = `${year || ""}${month.padStart(2, "0")}${day.padStart(2, "0")}`
  } else {
    normalizedFilterValue = normalizeValue(value, columnId)
  }

  const normalizedCellValue = normalizeValue(cellValue, columnId)
  return normalizedCellValue.includes(normalizedFilterValue)
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  if (filterHacks(row, columnId, value)) {
    return true
  }

  const ACCOUNTING_SEARCH_COLUMNS = ["reason_code", "expense_account"]
  if (value?.toLowerCase() === "uncoded" && ACCOUNTING_SEARCH_COLUMNS.includes(columnId)) {
    if (row.getValue(columnId) === "") {
      return true
    }
  }

  let itemValue = row.getValue(columnId)
  if (!itemValue) return false

  // Use the same normalization for both values
  const normalizedValue = normalizeValue(value, columnId)
  const normalizedItemValue = normalizeValue(itemValue, columnId)

  const itemRank = rankItem(normalizedItemValue, normalizedValue)
  addMeta({ itemRank })

  return itemRank.passed
}

const filterHacks = (row: any, columnId: string, value: string) => {
  if (value.toLowerCase() === "misc") {
    return row.getValue("category") === ""
  }

  if (columnId === "status_value" && value.toLowerCase() === "new") {
    if (row.getValue("status_value") === "") {
      return true
    }
  }
}

// The default filter function used by TanStack Table
export const defaultFilter: FilterFn<any> = (row, columnId, value) => {
  // if (columnId === "has_splits" || columnId === "is_parent") {
  //   const cellValue = row.getValue(columnId)
  //   const boolValue = value.toLowerCase() === "true"
  //   return cellValue === boolValue
  // }
  if (filterHacks(row, columnId, value)) {
    return true
  }


  const cellValue = row.getValue(columnId)
  if (!cellValue) return false
  return String(cellValue).toLowerCase().includes(String(value).toLowerCase())
}

// Add this type definition
type BulkCategoryUpdateResponse = {
  message: string
}

export function DeductionTable<TValue>({
                                         loading,
                                         columns,
                                         data,
                                       }: DataTableProps<DeductionResponse, TValue>) {
  const [location, setLocation] = useLocation()

  const user = UserState.use(u => u!)
  const tableState = DataTableState.use(ts => ts!)

  const [requestBackupDisabled, setRequestBackupDisabled] = useState(false)
  const [assignmentPopoverOpen, setAssignmentPopoverOpen] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const [isDownloadingBackup, setIsDownloadingBackup] = useState(false)
  const [isDownloadingDeductions, setIsDownloadingDeductions] = useState(false)

  function handleChangeGlobalFilter(value: string) {
    DataTableState.set({
      ...tableState,
      search: value,
    })
  }

  const table = useMemo(() => {
    return useReactTable<DeductionResponse>({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      globalFilterFn: fuzzyFilter,
      filterFns: {
        dateFilter: dateFilter,
        default: defaultFilter,
      },
      // @ts-ignore
      defaultFilterFn: defaultFilter,
      onColumnFiltersChange: function(updater: any) {
        const filters = updater(tableState.columnFilters!) as ColumnFiltersState
        DataTableState.set({ ...tableState, columnFilters: filters })
      },
      onColumnVisibilityChange: function(updater: any) {
        const visibility = updater(tableState.columnVisibility!) as VisibilityState
        DataTableState.set({ ...tableState, columnVisibility: visibility })
      },
      onGlobalFilterChange: handleChangeGlobalFilter,
      onRowSelectionChange: function(updater: any) {
        const selection = updater(tableState.rowSelection!) as RowSelectionState
        DataTableState.set({ ...tableState, rowSelection: selection })
      },
      onSortingChange: function(updater: any) {
        let new_sort = updater(tableState.sorting!) as SortingState
        new_sort.splice(0, 0, { id: "validated" } as ColumnSort)
        DataTableState.set(s => ({
          ...s,
          sorting: new_sort,
        }))
      },
      autoResetPageIndex: false,
      onPaginationChange: function(updater: any) {
        let new_pagination = updater(tableState.pagination!) as PaginationState
        DataTableState.set({ ...tableState, pagination: new_pagination })
      },
      state: {
        columnVisibility: tableState.columnVisibility,
        rowSelection: tableState.rowSelection as RowSelectionState,
        sorting: tableState.sorting,
        globalFilter: tableState.search,
        pagination: tableState.pagination,
        columnFilters: window.location.pathname.includes("deductions")
          ? tableState.columnFilters
          : [],
      },
    })
  }, [data, columns, tableState])

  useAsyncEffect(async () => {
    let res = await api_fetch<User[]>("/users")
    if (!res.ok) {
      console.error(`Failed to fetch users`)
      return
    }
    setUsers(res.value.data)
  }, [])

  // Handle data change to keep pagination state
  // TODO(joey): there's a bug now where if you're on a later page and you filter, it doesn't reset to page 0
  useEffect(() => {
    const pageIndex = tableState.pagination?.pageIndex ?? 0
    const pageSize = tableState.pagination?.pageSize ?? 50
    if (data.length < pageIndex * pageSize) {
      DataTableState.set({
        ...tableState,
        pagination: { pageIndex: 0, pageSize },
      })
    }
  }, [data, tableState.pagination])

  function getDownloadDataTitle(): string {
    return location.includes("deductions")
      ? "Deductions"
      : location.includes("accounting")
        ? "Accounting Codes"
        : "Invoices"
  }

  async function downloadDeductionsCsv() {
    if (!user?.org_id) return

    try {
      setIsDownloadingDeductions(true)
      const filteredData = table.getFilteredRowModel().rows.map(row => row.original)
      const dataToDownload = filteredData.length > 0 ? filteredData : data
      let csv = buildCsv(collectDownloadData(dataToDownload), user.org_id)
      downloadCsv(csv, getDownloadDataTitle())
    } finally {
      setIsDownloadingDeductions(false)
    }
  }

  async function downloadBackupCsv() {
    try {
      setIsDownloadingBackup(true)
      const res = await api_fetch<Record<string, number | string | boolean>[]>("/backup")
      if (!res.ok) {
        throw new Error("Failed to fetch data")
      }
      const filteredIds = new Set(table.getFilteredRowModel().rows.map(row => row.original.id))
      const filteredData = res.value.data.filter(row => filteredIds.has(row.deduction_id?.toString()))

      const csv = convertBackupToCsv(collectDownloadData(filteredData))
      downloadCsv(csv, "backup")
    } catch (error) {
      console.error("Error downloading backup CSV:", error)
      toast({
        title: "Error",
        description: "Failed to download backup CSV",
      })
    } finally {
      setIsDownloadingBackup(false)
    }
  }

  const isRowSelected = tableState.rowSelection && Object.keys(tableState.rowSelection).length > 0

  function handleRowClick(ded: DeductionResponse) {
    if (ded.source === "target") {
      window.open(getTargetUrl(ded), "_blank")
    } else if (ded.source === "cvs") {
      window.open(`https://cvs.traversesystems.com/#/inquiry/charge?keyNum=${ded.invoice_number}`, "_blank")
    } else if (location.includes("accounting")) {
      setLocation(`/split/${ded.id}`)
    } else {
      setLocation(`/deduction/${ded.id}`)
    }
  }

  const resetRowSelection = () => DataTableState.set({ ...tableState, rowSelection: {} })

  function handleOperationSuccess(message: string) {
    toast({
      title: "Success",
      description: message,
    })
    StatusFlag.set(flag => !flag)
    resetRowSelection()
  }

  async function handleTaskAssignment(values: any) {
    const selectedRows = table.getSelectedRowModel().rows
    let body = selectedRows.map(row => ({
      deduction_id: row.original.id,
      user_id: values.user_id,
      task_type: values.taskType,
      backup_type: values.backupType,
      note: values.note,
    }))

    let res = await api_fetch("/tasks", { body })

    if (!res.ok) {
      toast({
        title: "Error",
        description: "Failed to assign tasks",
      })
      console.error(`Failed to assign tasks`)
      return
    }

    handleOperationSuccess(
      `${selectedRows.length} task${selectedRows.length > 1 ? "s" : ""} assigned`,
    )
    setAssignmentPopoverOpen(false)
  }

  async function handleSelectAccountingMatch(match: ReasonCode) {
    const selectedRows = table.getSelectedRowModel().rows
    let body = selectedRows.map(row => ({
      deduction_id: row.original.id,
      reason_code_id: match.id,
    }))

    let res = await api_fetch("/match_deductions", { body })

    if (!res.ok) {
      toast({
        title: "Error",
        description: "Failed to assign accounting match",
      })
      console.error(`Failed to assign accounting match`)
      return
    }

    handleOperationSuccess(
      `${selectedRows.length} accounting match${selectedRows.length > 1 ? "es" : ""} completed`,
    )
  }

  async function bulkRequestBackup() {
    if (requestBackupDisabled) return

    setRequestBackupDisabled(true)
    let rows = table.getSelectedRowModel().rows
    let result = await bulk_request_backup(rows.map(row => row.original))

    setRequestBackupDisabled(false)

    handleOperationSuccess(
      `Backup requested for ${rows.length} deduction${rows.length > 1 ? "s" : ""}`,
    )
  }

  async function bulkUpdateStatus(status: String) {
    let rows = table.getSelectedRowModel().rows
    let body = rows.map(row => ({
      deduction_id: row.original.id,
      status,
      notes: "bulk",
    }))

    let res = await api_fetch("/deductions/statuses/bulk", { body })

    if (!res.ok) {
      toast({
        title: "Error",
        description: `Failed to mark deductions as ${status}`,
      })
      console.error(`Failed to mark deductions as ${status}`)
      return
    }

    handleOperationSuccess(
      `${rows.length} deduction${rows.length > 1 ? "s" : ""} marked as ${status}`,
    )
  }

  async function handleBulkCategoryUpdate(values: z.infer<typeof CATEGORY_FORM_SCHEMA>) {
    const selectedRows = table.getSelectedRowModel().rows
    let body = selectedRows.map(row => ({
      deduction_id: row.original.id,
      ...values,
    }))

    let res = await api_fetch<BulkCategoryUpdateResponse>("/deductions/categories/bulk", {
      method: "POST",
      body,
    })

    if (!res.ok) {
      toast({
        title: "Error",
        description: "Failed to update categories",
      })
      console.error(`Failed to update categories: ${res.error}`)
      return
    }

    handleOperationSuccess(
      `Categories updated for ${selectedRows.length} deduction${selectedRows.length > 1 ? "s" : ""}`,
    )
  }

  function clearAllFilters() {
    let { startDate, endDate } = getStartEnd("allTime")
    DataTableState.set(current => ({
      ...INITIAL_TABLE_STATE, // reset everything to initial except for the below:
      transactionTypes: TRANSACTION_TYPES.map(t => t.value),
      startDate,
      endDate,
      columnVisibility: current.columnVisibility,
      sorting: current.sorting,
    }))
  }

  const MarkNewButton = (
    <Button className="pr-2 bg-red-300-opacity-10" onClick={() => bulkUpdateStatus("new")}>
      <span className="text-red-400">Mark new</span>
      <Check className="h-4 text-red-400" />
    </Button>
  )

  let rows = table.getRowModel().rows
  return (
    <>
      <div className="flex items-center justify-between py-4">
        <div className="flex w-full space-x-2">
          <div className="flex space-x-2">
            <TransactionTypeFilter className="" />
            <Button 
              variant="outline" 
              className="text-slate-500"
              onClick={clearAllFilters}
            >
              Clear Filters
            </Button>
          </div>
          <SearchInput
            value={tableState.search ?? ""}
            onChange={value => handleChangeGlobalFilter(String(value))}
            className="w-full p-2 font-lg"
            placeholder="Search..."
          />
          <Button
            className="text-slate-500"
            onClick={downloadBackupCsv}
            variant="outline"
            disabled={isDownloadingBackup}
          >
            {isDownloadingBackup ? (
              <Loader2 className="h-4 animate-spin" />
            ) : (
              <Download className="h-4" />
            )}
            Backup
          </Button>
          <div class="flex justify-end mr-3">
            <Button
              className="px-2 text-slate-500"
              onClick={downloadDeductionsCsv}
              variant="outline"
              disabled={isDownloadingDeductions}
            >
              {isDownloadingDeductions ? (
                <Loader2 className="h-4 animate-spin" />
              ) : (
                <Download className="h-4" />
              )}
              {getDownloadDataTitle()}
            </Button>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto text-slate-500">
                <Columns2 className="h-4" />
                Columns
                <ChevronDown className="h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="overflow-y-scroll" style={{maxHeight: "var(--radix-dropdown-menu-content-available-height)"}}>
              {table
                .getAllColumns()
                .filter(column => column.getCanHide())
                .map(column => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      checked={column.getIsVisible()}
                      onCheckedChange={(value: any) => column.toggleVisibility(!!value)}>
                      {capitalCase(column.id)}
                    </DropdownMenuCheckboxItem>
                  )
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <Table>
        <TableHeader className="bg-slate-50">
          {table.getHeaderGroups().map((headerGroup, idx) => (
            <TableRow key={`${headerGroup.id}-${idx}`}>
              {headerGroup.headers.map((header, headerIdx) => {
                return (
                  <TableHead
                    key={`${header.id}-${headerIdx}`}
                    className="first:rounded-tl-md last:rounded-tr-md">
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        {loading ? (
          <TableBody>
            <TableRow>
              {Array.from({ length: columns.length }).map((_, cellIndex) => (
                <TableCell key={cellIndex}>
                  <Skeleton className="w-[100px] h-[20px] rounded-full bg-slate-500" />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        ) : (
          <TableBody>
            {rows.length ? (
              rows.map((row, idx) => {
                let task = row.original.task
                return (
                  <TableRow
                    key={`${row.id}-${idx}`}
                    className={
                      task?.user_email === user.email && task.status === "pending" ? `bg-plue-100` : ""
                    }
                    onClick={() => handleRowClick(row.original)}
                    data-state={row.getIsSelected() && "selected"}>
                    {row.getVisibleCells().map((cell, cellIdx) => (
                      <TableCell
                        className="hover:cursor-pointer"
                        key={`${cell.id}-${cellIdx}`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
      {isRowSelected && (
        <div class="flex fixed bottom-10 z-10 bg-slate-900 rounded-lg border left-1/2 transform -translate-x-1/2 p-2">
          <div className="flex justify-start flex-grow">
            <Button onClick={resetRowSelection} className="text-gray-400" variant="link">
              {`${table.getFilteredSelectedRowModel().rows.length} selected`}
              <X className="h-4" />
            </Button>
          </div>
          <Separator orientation="vertical" className="h-10 bg-slate-700 mr-3" />
          <div className="flex justify-end space-x-3">
            <Button
              className="pr-2 bg-orange-400-opacity-10"
              onClick={() => bulkRequestBackup()}
              disabled={requestBackupDisabled}>
              <span class="text-orange-500 ">Request backup</span>
              <File className="h-4 text-orange-500" />
            </Button>
            {table
              .getFilteredSelectedRowModel()
              .rows.every(row => row.original.status_value?.toLowerCase() === "disputable") ? (
              MarkNewButton
            ) : (
              <Button
                className="pr-2 bg-sky-400-opacity-10"
                onClick={() => bulkUpdateStatus("disputable")}>
                <span class="text-sky-500">Mark disputable</span>
                <FileWarning className="h-4 text-sky-500" />
              </Button>
            )}
            {table
              .getFilteredSelectedRowModel()
              .rows.every(row => row.original.status_value?.toLowerCase() === "validated") ? (
              MarkNewButton
            ) : (
              <Button
                className="pr-2 bg-green-500-opacity-10"
                onClick={() => bulkUpdateStatus("validated")}>
                <span className="text-green-600">Mark validated</span>
                <Check className="h-4 text-green-600" />
              </Button>
            )}

            {/* add additional popover if it's the accounting screen... */}
            {location.includes("accounting") && (
              <AccountingMatchPopover onSelectMatch={handleSelectAccountingMatch} />
            )}

            <Popover
              open={assignmentPopoverOpen}
              onOpenChange={() => setAssignmentPopoverOpen(true)}>
              <PopoverTrigger>
                {" "}
                <Button className="pr-2 bg-yellow-500-opacity-10">
                  <span class="text-yellow-600">Assign task</span>
                  <CircleCheck className="h-4 text-yellow-600" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[324px]">
                <AssignmentForm users={users} onSubmit={handleTaskAssignment} />
              </PopoverContent>
            </Popover>

            <Popover>
              <PopoverTrigger asChild>
                <Button className="pr-2 bg-purple-500-opacity-10">
                  <span className="text-purple-600">Update category</span>
                  <Tag className="h-4 text-purple-600" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="pb-48" collisionPadding={{ bottom: 200 }}>
                <CategoryForm
                  deductionId={table.getSelectedRowModel()?.rows[0]?.original?.id}
                  onSubmit={handleBulkCategoryUpdate}
                />
              </PopoverContent>
            </Popover>
          </div>
        </div>
      )}
      <div class="flex items-center justify-between p-2">
        {/* @ts-ignore */}
        <PageControls table={table} />
      </div>
    </>
  )
}

if (import.meta.vitest) {
  vi.mock("src/api/client", () => ({
    api_fetch: vi.fn(),
  }))

  vi.mock("./util", () => ({
    convertToCsv: vi.fn(),
    downloadCsv: vi.fn(),
    filterOutInternalColumns: vi.fn(),
    getTargetUrl: vi.fn(),
  }))

  describe("DataTable helpers and handlers", () => {
    describe("fuzzyFilter", () => {
      const mockAddMeta = vi.fn()

      it("should return true for \"misc\" search when category is empty, regardless of column being filtered", () => {
        const row = {
          getValue: vi.fn(key => (key === "category" ? "" : "value")),
        }
        // Should match empty category when searching any column
        // @ts-ignore
        expect(fuzzyFilter(row, "category", "misc", mockAddMeta)).toBe(true)
        // @ts-ignore
        expect(fuzzyFilter(row, "description", "misc", mockAddMeta)).toBe(true)
        // @ts-ignore
        expect(fuzzyFilter(row, "amount", "misc", mockAddMeta)).toBe(true)

        // Should not match when category has a value
        const rowWithCategory = {
          getValue: vi.fn(key => "some category"),
        }
        // @ts-ignore
        expect(fuzzyFilter(rowWithCategory, "category", "misc", mockAddMeta)).toBe(false)
        // @ts-ignore
        expect(fuzzyFilter(rowWithCategory, "description", "misc", mockAddMeta)).toBe(false)

        // Should not fuzzy match words containing "misc"
        const rowWithMiscInText = {
          getValue: vi.fn(key => (key === "description" ? "miscellaneous" : "")),
        }
        // @ts-ignore
        expect(fuzzyFilter(rowWithMiscInText, "description", "misc", mockAddMeta)).toBe(true) // true because category is empty
      })

      it("should return true for \"new\" when status_value is empty", () => {
        const row = {
          getValue: vi.fn(key => (key === "status_value" ? "" : "value")),
        }
        // @ts-ignore
        expect(fuzzyFilter(row, "status_value", "new", mockAddMeta)).toBe(true)
      })

      it("should return false for \"new\" when status_value is not empty", () => {
        const row = {
          getValue: vi.fn(() => "active"),
        }
        // @ts-ignore
        expect(fuzzyFilter(row, "status_value", "new", mockAddMeta)).toBe(false)
      })

      it("should return true for \"uncoded\" when reason_code or expense_account is empty", () => {
        const row = {
          getValue: vi.fn(key =>
            key === "reason_code" || key === "expense_account" ? "" : "value",
          ),
        }
        // @ts-ignore
        expect(fuzzyFilter(row, "reason_code", "uncoded", mockAddMeta)).toBe(true)
        // @ts-ignore
        expect(fuzzyFilter(row, "expense_account", "uncoded", mockAddMeta)).toBe(true)
      })

      it("should return false for \"uncoded\" when reason_code and expense_account are not empty", () => {
        const row = {
          getValue: vi.fn(() => "some value"),
        }
        // @ts-ignore
        expect(fuzzyFilter(row, "reason_code", "uncoded", mockAddMeta)).toBe(false)
        // @ts-ignore
        expect(fuzzyFilter(row, "expense_account", "uncoded", mockAddMeta)).toBe(false)
      })

      it("should use rankItem for fuzzy matching when not handling special cases", () => {
        const row = {
          getValue: vi.fn(() => "test value"),
        }
        // @ts-ignore
        expect(fuzzyFilter(row, "some_column", "test", mockAddMeta)).toBe(true)
        // @ts-ignore
        expect(fuzzyFilter(row, "some_column", "value", mockAddMeta)).toBe(true)
        // @ts-ignore
        expect(fuzzyFilter(row, "some_column", "other", mockAddMeta)).toBe(false)
      })

      it("should return false when item value is falsy", () => {
        const row = {
          getValue: vi.fn(() => ""),
        }
        // @ts-ignore
        expect(fuzzyFilter(row, "some_column", "test", mockAddMeta)).toBe(false)
      })
    })

    //   describe("downloadBackupCsv", () => {
    //     beforeEach(() => {
    //       vi.resetAllMocks()
    //     })

    //             expect(consoleSpy).toHaveBeenCalledWith("Error downloading backup CSV:", expect.any(Error))
    //     })
    //   })
  })

  describe("dateFilter", () => {
    // Helper to create a mock row with a date value
    const createRow = (date: string) => ({
      getValue: () => date,
    })

    test("matches MM/DD format against ISO dates", () => {
      expect(dateFilter(createRow("2024-06-27"), "invoice_date", "6/27")).toBe(true)
      expect(dateFilter(createRow("2024-06-27"), "invoice_date", "6/28")).toBe(false)
      expect(dateFilter(createRow("2024-07-27"), "invoice_date", "6/27")).toBe(false)
    })

    test("handles single digit month/day with or without leading zeros", () => {
      expect(dateFilter(createRow("2024-06-07"), "invoice_date", "6/7")).toBe(true)
      expect(dateFilter(createRow("2024-06-07"), "invoice_date", "06/07")).toBe(true)
      expect(dateFilter(createRow("2024-06-07"), "invoice_date", "6/07")).toBe(true)
    })

    test("matches full ISO date format exactly", () => {
      expect(dateFilter(createRow("2024-06-27"), "check_date", "2024-06-27")).toBe(true)
      expect(dateFilter(createRow("2024-06-27"), "check_date", "2023-06-27")).toBe(false)
    })

    test("handles empty and invalid values", () => {
      expect(dateFilter(createRow(""), "date", "6/27")).toBe(false)
      expect(dateFilter(createRow("invalid"), "date", "6/27")).toBe(false)
      expect(dateFilter(createRow("2024-06-27"), "date", "invalid")).toBe(false)
    })

    test("handles different date separators", () => {
      expect(dateFilter(createRow("2024-06-27"), "date", "6-27")).toBe(true)
      expect(dateFilter(createRow("2024-06-27"), "date", "6/27")).toBe(true)
    })
  })
}
