export function Logo() {
  return (
    <div class="flex flex-shrink-0 items-center mx-auto">
      <img
        class="block h-8 w-8 mr-2"
        src="/roundedicon.png"
        alt="MarginWiz"
      />
      <img
        class="block h-6 mr-2 mt-1"
        src="/wordmark.svg"
        alt="MarginWiz"
      />
    </div>
  )
}
