export enum TaskStatus {
  Pending = "pending",
  Completed = "completed",
  Archived = "archived",
}

export enum TaskType {
  FileDispute = "file-dispute",
  PullBackup = "pull-backup",
  Followup = "followup",
  Validate = "validate",
  MapAccountingCode = "map-accounting-code",
  Expense = "expense",
}

export interface TaskInfo {
  type: TaskType
  status: TaskStatus
  user_id: string // Assuming Uuid is a string
  created_at: string // Assuming DateTime is a string
  assigner_email: string
  id: string // Assuming Uuid is a string
  user_email: string
  backup_type?: string
  due_date?: string
  note?: string
}
