import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { classes } from "@/utils"
import { BarChart, BookOpenCheck, Home, LineChart, LogOut, Settings } from "lucide-react"
import { ComponentType } from "preact"
import { Link, useLocation } from "wouter-preact"

interface SidebarItemProps {
  href: string
  icon: ComponentType<Omit<React.SVGProps<SVGSVGElement>, "size">> // Adjusted to omit 'size' prop
  tooltip: string
}

const SidebarItem: React.FC<SidebarItemProps> = ({ href, icon: Icon, tooltip }) => {
  const [location] = useLocation()
  const isSelected = location.toLowerCase().includes(href.toLowerCase())
  const queryParams = location.split("?")[1] || ""

  return (
    <Tooltip>
      <TooltipTrigger>
        <a
          href={`${href}?${queryParams}`}
          className={classes(
            "flex h-9 w-9 items-center justify-center text-muted-foreground transition-colors hover:text-plue-900 md:h-8 md:w-8",
            isSelected ? "border-l-4 border-plue-900 -ml-6" : "",
          )}
        >
          <div className={classes(isSelected ? "ml-6" : "")}>
            <Icon className={classes("h-5 w-5", `${isSelected ? "text-plue-900" : ""}`)} />
          </div>
          <span className="sr-only">{tooltip}</span>
        </a>
      </TooltipTrigger>
      <TooltipContent side="right">{tooltip}</TooltipContent>
    </Tooltip>
  )
}

export function Sidebar() {
  return (
    <div className="fixed inset-y-0 left-0 z-10 w-14 flex flex-col border-r bg-background sm:flex">
      <TooltipProvider delayDuration={0}>
        <div className="flex flex-col items-center gap-4 px-2 sm:py-5">
          <Link href="#">
            <img class="block h-8 w-8" src="/roundedicon.png" alt="Promoted" />
          </Link>
          <SidebarItem href="/deductions" icon={Home} tooltip="Deductions" />
          <SidebarItem href="/sales" icon={BarChart} tooltip="Analytics" />
          <SidebarItem href="/analytics" icon={LineChart} tooltip="Analytics" />
          <SidebarItem href="/accounting" icon={BookOpenCheck} tooltip="Accounting" />
          {/* <SidebarItem href="#" icon={Calendar} tooltip="Trade planning" />  */}
        </div>
        <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
          <SidebarItem href="/settings" icon={Settings} tooltip="Settings" />
          <SidebarItem href="/logout" icon={LogOut} tooltip="Sign out" />
        </nav>
      </TooltipProvider>
    </div>
  )
}
