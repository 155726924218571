import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { ActiveFilterIcon } from "@/global_filter/active_filter_icon"
import { Search, X } from "lucide-react"
import { HTMLAttributes, useEffect, useRef, useState } from "preact/compat"

interface SearchInputProps {
  value: string | number
  onChange: (value: string | number) => void
  autoFocus?: boolean
}

type Props = SearchInputProps & Omit<HTMLAttributes<HTMLInputElement>, "onChange">

export function SearchInput({ value: initialValue, onChange, className, autoFocus, ...props }: Props) {
  const [value, setValue] = useState(initialValue)
  const [activeFilter, setActiveFilter] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  // Sync internal state when initialValue changes (e.g. when filters are cleared)
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  // Automatically remove search filter when input is cleared
  useEffect(() => {
    if (value === "") {
      onChange("")
      setActiveFilter(false)
    }
  }, [value])

  const handleSearch = () => {
    onChange(value)
    setActiveFilter(true)
  }

  const handleClear = () => {
    setValue("")
    onChange("")
    setActiveFilter(false)
  }

  const handleInputChange = (e: Event) => {
    const newValue = (e.target as HTMLInputElement).value
    setValue(newValue)
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSearch()
    }
  }

  const isFiltering = value !== "" && activeFilter
  const showSearchPrompt = value !== "" && !activeFilter

  return (
    <div className="relative flex-1">
      {/* @ts-ignore */}
      <Input 
        {...props} 
        ref={inputRef}
        value={value} 
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className={`pr-20 ${className}`} // Make room for the buttons
      />
      <div className="absolute right-0 top-0 h-full flex items-center gap-1 pr-2">
        {isFiltering && (
          <>
            <Button
              onClick={handleClear}
              variant="ghost"
              // @ts-ignore
              size="icon"
              className="h-8 w-8 text-slate-500 hover:text-slate-700"
            >
              <X className="h-4 w-4" />
            </Button>
            <ActiveFilterIcon />
          </>
        )}
        <Button 
          onClick={handleSearch}
          variant="ghost"
          // @ts-ignore
          size="icon"
          className={`h-8 ${showSearchPrompt ? 'w-32' : 'w-8'} text-slate-500 hover:text-slate-700`}
        >
          {showSearchPrompt ? (
            <>
              Click to search
              <Search className="h-4 w-4" />
            </>
          ) : (
            <Search className="h-4 w-4" />
          )}
        </Button>
      </div>
    </div>
  )
}
  