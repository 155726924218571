import { api_fetch } from "@/api/client"
import { UserState } from "@/auth/user"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { classes, isSuperCoffeeUser } from "@/utils"
import { Check, ChevronsUpDown } from "lucide-react"
import { useEffect, useRef, useState } from "preact/hooks"
import { EditableCell, EditableCellProps } from "./backup_accounting_columns"

export type Customer = {
  id: string
  name: string
  remote_id: string
}

export const CustomerCombobox = ({ getValue, row, column, table }: EditableCellProps) => {
  const [open, setOpen] = useState(false)
  const [customers, setCustomers] = useState<Customer[]>([])
  const [selectedValue, setSelectedValue] = useState<string>(getValue() as string)
  const commandListRef = useRef<HTMLDivElement>(null)
  const user = UserState.use()
  const isSCUser = isSuperCoffeeUser(user?.org_id)
  const isCustomerName = column.id === "customer_name"

  useEffect(() => {
    const fetchCustomers = async () => {
      const res = await api_fetch<Customer[]>("/customers", { use_cache: true })
      if (res.ok) {
        setCustomers(res.value.data)
      }
    }
    if (isSCUser) {
      fetchCustomers()
    }
  }, [isSCUser])

  const handleSearchChange = () => {
    if (commandListRef.current) {
      commandListRef.current.scrollTop = 0
    }
  }

  const handleSelect = (customer: Customer) => {
    setSelectedValue(customer.name)
    table.options.meta?.updateData(row.index, column.id, customer.name)
    // We could store the remote_id here if needed later
    setOpen(false)
  }

  // Return regular editable cell for non-Super Coffee users
  if (!isSCUser) {
    return <EditableCell getValue={getValue} row={row} column={column} table={table} />
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button 
          variant="outline" 
          className={classes(
            "w-full justify-start text-left font-normal text-xs h-8",
            !selectedValue && isCustomerName && "border-red-500"
          )}
        >
          <div className="flex items-center gap-2 truncate">
            {selectedValue ? (
              <span className="truncate">{selectedValue}</span>
            ) : (
              <>
                <ChevronsUpDown className="h-4 w-4 shrink-0" />
                <span>{isCustomerName ? "Select customer..." : "Select retailer..."}</span>
              </>
            )}
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0" align="start">
        <Command>
          <CommandInput 
            placeholder={isCustomerName ? "Search customers..." : "Search retailers..."} 
            onValueChange={handleSearchChange}
            className="h-9"
          />
          <CommandList ref={commandListRef}>
            <CommandEmpty>No {isCustomerName ? "customers" : "retailers"} found.</CommandEmpty>
            <CommandGroup>
              {customers.map((customer, index) => (
                <CommandItem
                  key={customer.id}
                  value={customer.name}
                  className={`${index % 2 === 0 ? 'bg-plue-50' : ''} ${
                    selectedValue === customer.name ? 'bg-plue-100' : ''
                  }`}
                  onSelect={() => handleSelect(customer)}
                >
                  <Check
                    className={classes(
                      "mr-2 h-4 w-4",
                      selectedValue === customer.name ? "opacity-100" : "opacity-0"
                    )}
                  />
                  <span className="truncate">{customer.name}</span>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
} 