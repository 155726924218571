import { api_fetch } from "@/api/client.tsx"
import { DeductionResponse } from "@/api/deduction.tsx"
import { UserState } from "@/auth/user.tsx"
import { SimpleSelect } from "@/components/simple_select.tsx"
import { toast } from "@/components/ui/use-toast.ts"
import { isSuperCoffeeUser } from "@/utils/index.tsx"
import { STATUS_ICONS } from "./table/columns.tsx"

// Base status type
type BaseStatusValue = 'new' | 'validated' | 'disputable' | 'backup_requested' | 
  'backup_received' | 'review' | 'disputed' | 'won' | 'lost'

// Super user additional statuses
type SuperUserStatusValue = 'expensable' | 'pending'

// Combined type that makes super user values optional
export type StatusValue = BaseStatusValue | SuperUserStatusValue

// Type the object to accept all base values and optional super user values
export let STATUS_VALUES: Record<BaseStatusValue, string> & Partial<Record<SuperUserStatusValue, string>> = {
  new: "New",
  validated: "Validated",
  disputable: "Disputable",
  backup_requested: "Backup Requested",
  backup_received: "Backup Received",
  review: "Review",
  disputed: "Disputed",
  won: "Won",
  expensable: "Expensable",
  lost: "Lost",
}

interface StatusSelectProps {
  deduction: DeductionResponse
  onStatusChange?: () => void
}

export function StatusSelect({ deduction, onStatusChange }: StatusSelectProps) {
  let initialValue = deduction.status_value || "new"
  const user = UserState.use()
  if (isSuperCoffeeUser(user?.org_id)) {
    STATUS_VALUES = {
      ...STATUS_VALUES,
      pending: "Pending",
    }
  }

  const handleOnChange = async (_s: string) => {
    let newStatus = _s as keyof typeof STATUS_VALUES
    const res = await api_fetch(`/deductions/status`, {
      method: "POST",
      body: {
        deduction_id: deduction.id,
        status: newStatus,
      },
    })

    if (res.ok) {
      console.log("Status updated successfully")
      toast({
        title: "Status Updated",
        description: `Status changed to ${STATUS_VALUES[newStatus]}`,
      })
      onStatusChange?.()
    } else {
      console.error("Failed to update status")
    }
  }

  return (
    <SimpleSelect
      value={initialValue}
      onChange={handleOnChange}
      values={STATUS_VALUES}
      icons={STATUS_ICONS}
    />
  )
}
