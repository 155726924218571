import { UserState } from "@/auth/user"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from "@/components/ui/command"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { classes, isSuperCoffeeUser } from "@/utils"
import { Check, ChevronsUpDown } from "lucide-react"
import { useEffect, useRef, useState } from "preact/hooks"
import { EditableCell, EditableCellProps } from "./backup_accounting_columns"

export type ProductOption = {
  value: string
  keywords?: string[]
}

export const createProductMatcher = <T extends string>(options: ProductOption[]) => {
  const findBestMatch = (input: string): T | undefined => {
    if (!input) return undefined

    const normalizedInput = input.toLowerCase().trim()

    // Direct match check
    const directMatch = options.find(
      option => option.value.toLowerCase() === normalizedInput
    )
    if (directMatch) return directMatch.value as T

    // Keyword match
    const matchedOption = options.find(option => {
      const keywords = [...(option.keywords || []), option.value.toLowerCase()]
      return keywords.some(keyword => normalizedInput.includes(keyword.toLowerCase()))
    })

    return matchedOption?.value as T | undefined
  }

  return {
    findBestMatch,
    options,
  }
}

// Product Categories
export const SUPER_COFFEE_CATEGORIES = createProductMatcher([
  { value: "Aseptic (12oz)", keywords: ["aseptic", "12oz"] },
  { value: "Creamer", keywords: ["cream"] },
  { value: "Espresso", keywords: ["espresso"] },
  { value: "Pods (Amazon Only)", keywords: ["pod", "amazon"] },
  { value: "Retort (Xxtra)", keywords: ["xxtra", "retort"] },
  { value: "MultiServe", keywords: ["multi", "serve"] },
  { value: "Protein Plus", keywords: ["protein", "plus"] },
  { value: "Retort (Old)", keywords: [] },
])

// Product Lines with keywords for matching
// todo: match UPCs https://docs.google.com/spreadsheets/d/1hUnq5qsmCcs5fFaLDs9yDuNfu2pZW8syKehbYKP-jNU/edit?gid=221312276#gid=221312276
export const SUPER_COFFEE_PRODUCTS = createProductMatcher([
  // 12oz Products
  { value: "12oz Vanilla", keywords: ["12oz", "vanilla", "aseptic"] },
  { value: "12oz Mocha", keywords: ["12oz", "mocha", "aseptic"] },
  { value: "12oz Hazelnut", keywords: ["12oz", "hazelnut", "aseptic"] },
  { value: "12oz Caramel", keywords: ["12oz", "caramel", "aseptic"] },
  { value: "12oz Pumpkin", keywords: ["12oz", "pumpkin", "aseptic"] },
  { value: "12oz Peppermint", keywords: ["12oz", "peppermint", "aseptic"] },

  // 15oz Products
  { value: "15oz Vanilla", keywords: ["15oz", "vanilla"] },
  { value: "15oz Mocha", keywords: ["15oz", "mocha"] },
  { value: "15oz Caramel", keywords: ["15oz", "caramel"] },

  // 11oz Espresso
  { value: "11oz Espresso Vanilla", keywords: ["11oz", "espresso", "vanilla"] },
  { value: "11oz Espresso Cream", keywords: ["11oz", "espresso", "cream"] },
  { value: "11oz Espresso Triple", keywords: ["11oz", "espresso", "triple"] },

  // Protein Plus
  { value: "12oz P+ Mocha", keywords: ["12oz", "protein plus", "p+", "mocha"] },

  // Creamer
  { value: "32oz Creamer Vanilla", keywords: ["32oz", "creamer", "vanilla"] },
  { value: "32oz Creamer Sweet Cream", keywords: ["32oz", "creamer", "sweet cream"] },

  // MultiServe
  { value: "50oz MultiServe Medium", keywords: ["50oz", "multi serve", "medium roast"] },
  { value: "50oz MultiServe Dark", keywords: ["50oz", "multi serve", "dark roast"] },

  // Discontinued 12oz Products
  { value: "12oz Caramel Waffle (disco'd)", keywords: ["12oz", "caramel waffle", "discontinued"] },
  { value: "12oz Cinammon Roll (disco'd)", keywords: ["12oz", "cinnamon roll", "discontinued"] },
  { value: "12oz Blueberry (disco'd)", keywords: ["12oz", "blueberry", "discontinued"] },
  { value: "12oz Coconut Mocha (disco'd)", keywords: ["12oz", "coconut mocha", "discontinued"] },
  { value: "12oz Cold Brew (disco'd)", keywords: ["12oz", "cold brew", "discontinued"] },
  { value: "12oz French Vanilla (disco'd)", keywords: ["12oz", "french vanilla", "discontinued"] },
  { value: "12oz Sweet Cream (disco'd)", keywords: ["12oz", "sweet cream", "discontinued"] },
  { value: "12oz SGD (disco'd)", keywords: ["12oz", "sgd", "discontinued"] },

  // Discontinued 11oz Products
  { value: "11oz Vanilla (disco'd)", keywords: ["11oz", "vanilla", "discontinued"] },
  { value: "11oz Mocha (disco'd)", keywords: ["11oz", "mocha", "discontinued"] },
  { value: "11oz Coconut Mocha (disco'd)", keywords: ["11oz", "coconut mocha", "discontinued"] },
  { value: "11oz Cold Brew (disco'd)", keywords: ["11oz", "cold brew", "discontinued"] },
  { value: "11oz French Vanilla (disco'd)", keywords: ["11oz", "french vanilla", "discontinued"] },
  { value: "11oz Sweet Cream (disco'd)", keywords: ["11oz", "sweet cream", "discontinued"] },

  // Discontinued 25.4oz Creamer
  { value: "25.4oz Creamer Vanilla (disco'd)", keywords: ["25.4oz", "creamer", "vanilla", "discontinued"] },
  { value: "25.4oz Creamer H&H (disco'd)", keywords: ["25.4oz", "creamer", "h&h", "half and half", "discontinued"] },
  { value: "25.4oz Creamer Sweet Cream (disco'd)", keywords: ["25.4oz", "creamer", "sweet cream", "discontinued"] },
  { value: "25.4oz Creamer Caramel (disco'd)", keywords: ["25.4oz", "creamer", "caramel", "discontinued"] },
  { value: "25.4oz Creamer Pumpkin (disco'd)", keywords: ["25.4oz", "creamer", "pumpkin", "discontinued"] },
  { value: "25.4oz Creamer Peppermint (disco'd)", keywords: ["25.4oz", "creamer", "peppermint", "discontinued"] },
  { value: "25.4oz Creamer French Vanilla (disco'd)", keywords: ["25.4oz", "creamer", "french vanilla", "discontinued"] },
  { value: "25.4oz Creamer Coconut Mocha (disco'd)", keywords: ["25.4oz", "creamer", "coconut mocha", "discontinued"] },
  { value: "25.4oz Creamer Hazelnut (disco'd)", keywords: ["25.4oz", "creamer", "hazelnut", "discontinued"] },
  { value: "25.4oz Creamer Caramel Waffle (disco'd)", keywords: ["25.4oz", "creamer", "caramel waffle", "discontinued"] },

  // Discontinued 11.2oz Creamer
  { value: "11.2oz Creamer French Vanilla (disco'd)", keywords: ["11.2oz", "creamer", "french vanilla", "discontinued"] },
  { value: "11.2oz Creamer Coconut Mocha (disco'd)", keywords: ["11.2oz", "creamer", "coconut mocha", "discontinued"] },
  { value: "11.2oz Creamer Caramel Waffle (disco'd)", keywords: ["11.2oz", "creamer", "caramel waffle", "discontinued"] },

  // Discontinued MultiServe
  { value: "32oz MultiServe Vanilla (disco'd)", keywords: ["32oz", "multi serve", "vanilla", "discontinued"] },
  { value: "32oz MultiServe Cold Brew (disco'd)", keywords: ["32oz", "multi serve", "cold brew", "discontinued"] },

  // Discontinued Glass MultiServe
  { value: "32oz MultiServe Vanilla GLASS (disco'd)", keywords: ["32oz", "multi serve", "vanilla", "glass", "discontinued"] },
  { value: "32oz MultiServe Mocha GLASS (disco'd)", keywords: ["32oz", "multi serve", "mocha", "glass", "discontinued"] },
  { value: "32oz MultiServe Cold Brew GLASS (disco'd)", keywords: ["32oz", "multi serve", "cold brew", "glass", "discontinued"] },
  { value: "32oz MultiServe French Vanilla GLASS (disco'd)", keywords: ["32oz", "multi serve", "french vanilla", "glass", "discontinued"] },
  { value: "32oz MultiServe Coconut Mocha GLASS (disco'd)", keywords: ["32oz", "multi serve", "coconut mocha", "glass", "discontinued"] },

  // Pods
  { value: "Pods - Dark Roast (disco'd)", keywords: ["pods", "dark roast", "discontinued"] },
  { value: "Pods - Hazelnut (disco'd)", keywords: ["pods", "hazelnut", "discontinued"] },
  { value: "Pods - Vanilla (disco'd)", keywords: ["pods", "vanilla", "discontinued"] },
  { value: "Pods - Mocha (disco'd)", keywords: ["pods", "mocha", "discontinued"] },
  { value: "Pods - Maple Pumpkin (disco'd)", keywords: ["pods", "maple pumpkin", "discontinued"] },
  { value: "Pods - WCP (disco'd)", keywords: ["pods", "wcp", "white chocolate peppermint", "discontinued"] },

  // Grounds
  { value: "Grounds - Dark Roast (disco'd)", keywords: ["grounds", "dark roast", "discontinued"] },
  { value: "Grounds - Hazelnut (disco'd)", keywords: ["grounds", "hazelnut", "discontinued"] },
  { value: "Grounds - Vanilla (disco'd)", keywords: ["grounds", "vanilla", "discontinued"] },
  { value: "Grounds - Mocha (disco'd)", keywords: ["grounds", "mocha", "discontinued"] },

  // 6oz Espresso
  { value: "6oz Espresso - Caramel (disco'd)", keywords: ["6oz", "espresso", "caramel", "discontinued"] },
  { value: "6oz Espresso - Vanilla (disco'd)", keywords: ["6oz", "espresso", "vanilla", "discontinued"] },
  { value: "6oz Espresso - Triple Shot (disco'd)", keywords: ["6oz", "espresso", "triple shot", "discontinued"] },

  // Energy Drinks
  { value: "Enrgy - Mango Peach (disco'd)", keywords: ["energy", "mango peach", "discontinued"] },
  { value: "Enrgy - Strawberry Lemon (disco'd)", keywords: ["energy", "strawberry lemon", "discontinued"] },
  { value: "Enrgy - Mixed Berry (disco'd)", keywords: ["energy", "mixed berry", "discontinued"] }
])

// Reusable dropdown cell component
export const createProductDropdownCell = ({
  options,
  findBestMatch,
}: ReturnType<typeof createProductMatcher>) => {
  return ({ getValue, row, column, table }: EditableCellProps) => {
    const user = UserState.use()
    const value = getValue() as string
    const isSCUser = isSuperCoffeeUser(user?.org_id)
    const [open, setOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState<string>(value)
    const commandListRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (isSCUser && !options.map(o => o.value).includes(value)) {
        const matchedValue = findBestMatch(value)
        if (matchedValue) {
          table.options.meta?.updateData(row.index, column.id, matchedValue)
        }
      }
    }, [value, isSCUser])

    const handleSearchChange = () => {
      // Reset scroll position when search input changes
      if (commandListRef.current) {
        commandListRef.current.scrollTop = 0
      }
    }

    const handleSelect = (newValue: string) => {
      setSelectedValue(newValue)
      table.options.meta?.updateData(row.index, column.id, newValue)
      setOpen(false)
    }

    if (!isSCUser) {
      return <EditableCell getValue={getValue} row={row} column={column} table={table} />
    }

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button 
            variant="outline" 
            className="w-full justify-start text-left font-normal text-xs h-8"
          >
            <div className="flex items-center gap-2 truncate">
              {selectedValue ? (
                <span className="truncate">{selectedValue}</span>
              ) : (
                <>
                  <ChevronsUpDown className="h-4 w-4 shrink-0" />
                  <span>Select...</span>
                </>
              )}
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[400px] p-0" align="start">
          <Command>
            <CommandInput 
              placeholder="Search..." 
              onValueChange={handleSearchChange}
              className="h-9"
            />
            <CommandList ref={commandListRef}>
              <CommandEmpty>No matches found.</CommandEmpty>
              <CommandGroup>
                {options.map((option, index) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    className={`${index % 2 === 0 ? 'bg-plue-50' : ''} ${
                      selectedValue === option.value ? 'bg-plue-100' : ''
                    }`}
                    onSelect={() => handleSelect(option.value)}
                  >
                    <Check
                      className={classes(
                        "mr-2 h-4 w-4",
                        selectedValue === option.value ? "opacity-100" : "opacity-0"
                      )}
                    />
                    <span className="truncate">{option.value}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    )
  }
} 