import { Decimal } from "decimal.js"

interface TotalDisplayProps {
  currentTotal: number
  targetAmount: number
}

export function TotalDisplay({ currentTotal, targetAmount }: TotalDisplayProps) {
  const difference = new Decimal(Math.abs(targetAmount)).minus(new Decimal(currentTotal))
  const isMatched = difference.abs().lessThan(new Decimal("0.001"))
  
  return (
    <div className="flex items-center gap-4 text-sm">
      <div className="ml-2">
        Coded: <span className="font-medium">${currentTotal.toFixed(2)}</span>
      </div>
      <div>
        Target: <span className="font-medium">${Math.abs(targetAmount).toFixed(2)}</span>
      </div>
      <div className={isMatched ? "text-green-600" : "text-amber-600"}>
        {isMatched ? (
          "✓ Matched"
        ) : (
          `Remaining: $${difference.toFixed(2)}`
        )}
      </div>
    </div>
  )
} 