import { fetch_query_data } from "@/api/query_data"
import { LoadingSpinner } from "@/app/loading"
import { UserState } from "@/auth/user"
import { DataTableState } from "@/deductions/table_state"
import { putTransactionType } from "@/global_filter/transaction_type"
import { currencyFormatter, isSuperCoffeeUser, useAsyncEffect } from "@/utils"
import { BarList } from "@tremor/react"
import { useState } from "preact/hooks"
import { Bar } from "./status_breakdown"

type ReasonCodeData = {
  name: string
  value: number
  amount: number
  color: string
}

const LIKELIHOOD_COLORS = {
  red: "red",
  yellow: "yellow",
  green: "green",
}

export function ReasonCodeBreakdown() {
  const [data, setData] = useState<ReasonCodeData[]>([])
  const [loading, setLoading] = useState(true)
  const [start, end, distributor] = DataTableState.use(s => [s.startDate, s.endDate, s.distributor])
  const user = UserState.use()

  useAsyncEffect(async () => {
    // Only fetch for specific org_id
    if (!user || !isSuperCoffeeUser(user.org_id)) {
      setLoading(false)
      return
    }

    let res = await fetch_query_data("reason_code", { start, end, distributor: distributor === "All Distributors" ? null : distributor })
    setLoading(false)
    if (!res.ok) {
      setData([])
      return
    }

    const { rows } = res.value
    const processedData = rows.map(row => ({
      name: `${row[0]} (${row[2]})`, // custom_category (count)
      value: row[2] as number, // count
      amount: row[3] as number, // total_amount
      color: LIKELIHOOD_COLORS[String(row[1]).toLowerCase() as keyof typeof LIKELIHOOD_COLORS] || "gray",
      key: `${row[0]}-${row[1]}`, // For unique identification
    }))

    setData(processedData)
  }, [start, end, distributor, user?.org_id])

  if (loading) {
    return <LoadingSpinner color="blue" />
  }

  if (!data.length || !user || !isSuperCoffeeUser(user.org_id)) {
    return null
  }

  return (
    <div class="flex">
      <BarList
        data={data}
        className="max-w-2xl grow"
        sortOrder="none"
        valueFormatter={(_: ReasonCodeData) => ""}
        onValueChange={(item: Bar<ReasonCodeData>) => {
          DataTableState.set(s => {
            return {
              ...s,
              search: "",
              transactionTypes: putTransactionType(s.transactionTypes, "deduction"),
              columnFilters: [{ id: "custom_category", value: item.key!.split("-")[0]! }],
              pagination: { pageIndex: 0, pageSize: s.pagination.pageSize },
            }
          })
        }}
      />
      <div class="xl:ml-6">
        {data.map((d, i) => (
          <div class="h-8 mb-1.5 items-center flex">
            {currencyFormatter(Math.abs(d.amount))}
          </div>
        ))}
      </div>
    </div>
  )
} 