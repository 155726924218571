import { Team } from "./team"
import { Testimonials } from "./testimonials"
import { Footer, Header, Interlude, Screenshot, Squiggles } from "./util"

export const DEMO_LINK = "https://calendly.com/kurtwolf/demo"

export function Landing() {
  return (
    <div className={"flex flex-col min-h-full w-full "}>
      <div class="bg-plue-100 min-h-screen">
        <Header />
        <Squiggles />
        <Hero />
        <Screenshot src="/img/dashboard.png" alt="Dashboard" />
        <AutomationStats />
        <Screenshot src="/img/detail.png" alt="Detail page" />
        <Testimonials />
        <Pricing />
        <Team />
        <Footer />
      </div>
    </div>
  )
}

function Hero() {
  return (
    <div className="mx-auto max-w-2xl py-8 sm:py-10 lg:py-16">
      <div className="text-left">
        <h1 className="text-center font-display mx-auto max-w-4xl text-5xl font-medium tracking-tight text-plue-900 sm:text-7xl">
          The easy button for{" "}
          <span className="relative whitespace-nowrap text-plue-600">
            <svg
              aria-hidden="true"
              viewBox="0 0 418 42"
              className="absolute left-0 top-2/3 h-[0.58em] w-full fill-plue-300/70"
              preserveAspectRatio="none">
              <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
            </svg>
            <span className="relative">deductions</span>
          </span>{" "}
        </h1>

        <div class="ml-3 text-center">
          {/* <p className="mt-6 text-lg leading-8 text-gray-600">
        Without <i>any</i> manual work on your end.
      </p> */}
          <p className="mt-6 text-xl leading-8 text-plue-700">
            Put your money back on your bottom line with the first AI platform that pulls all your
            data into one place so you can fight deductions in a few clicks
          </p>
        </div>

        <div className="my-10 flex items-center justify-center gap-x-6">
          <a href={DEMO_LINK} target="_blank" className="btn btn-tertiary">
            Book a demo
          </a>
          <a href="mailto:nat@marginwiz.com" class="btn btn-light">Contact us</a>
        </div>
      </div>
    </div>
  )
}

function Pricing() {
  // TODO: replace this with an ROI calculator that also shares the price?
  return (
    <div className="bg-plue-100 flex flex-col items-center justify-center px-4 py-24 text-plue-900">
      <Interlude
        badgeCopy="Pricing"
        title="Simple pricing"
        description="We ensure every brand gets measurable ROI with pricing based on volume of deductions, dollars saved, and number of distribution sources."
      />
      <a href={DEMO_LINK} target="_blank" className="btn btn-tertiary mb-12 -mt-8">
        Book a demo
      </a>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-5xl">
        {[
          {
            title: "Emerging",
            subtitle: "Brands < $3M",
            description:
              "Start fighting deductions as far back as a year with the platform made for emerging brands like you.",
          },
          {
            title: "Growth",
            subtitle: "Brands < $20M",
            description:
              "Automate your deductions and accounting workflows, with platform and service that guarantees an ROI.",
          },
          {
            title: "Scale",
            subtitle: "Brands > $20M",
            description:
              "For larger brands with many distributors and custom needs, we are excited to work together to optimize the difference between your gross and net revenue.",
          },
        ].map((stat, index) => (
          <div key={index} className="bg-white p-4 rounded-lg text-center">
            <h2 className="text-4xl font-bold mb-2">{stat.title}</h2>
            <h3 className="text-xl font-semibold mb-4">{stat.subtitle}</h3>
            <p className="text-plue-700">{stat.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

function AutomationStats() {
  return (
    <div className="bg-plue-100 flex flex-col items-center justify-center px-4 py-24 text-plue-900">
      <Interlude
        badgeCopy="Our Solution"
        title="Automate your deduction disputes"
      />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-5xl">
        {[
          {
            title: "15x",
            subtitle: "time savings",
            description:
              "File disputes in seconds, not minutes. Map deductions to the correct accounting codes automatically.",
          },
          {
            title: "+100",
            subtitle: "team happiness",
            description:
              "Let us handle your deductions so you and your team can focus on the work they love doing.",
          },
          {
            title: "25%+",
            subtitle: "profit margin boost",
            description: "Put the money you earned back on your bottom line, where it belongs.",
          },
        ].map((stat, index) => (
          <div key={index} className="text-center">
            <h2 className="text-4xl font-bold mb-2">{stat.title}</h2>
            <h3 className="text-xl font-semibold mb-4">{stat.subtitle}</h3>
            <p className="text-plue-700">{stat.description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
