import { api_fetch } from "@/api/client"
import { DeductionResponse, ReasonCode, ReasonCodeInfo } from "@/api/deduction"
import { Button } from "@/components/ui/button"
import { toast } from "@/components/ui/use-toast"
import { BackupTable } from "@/deductions/detail/backup_table"
import { StatusFlag } from "@/deductions/status_state"
import { collectDownloadData, convertBackupToCsv, downloadCsv } from "@/deductions/table/util"
import { displayFormattedDistributor } from "@/utils"
import { Decimal } from "decimal.js"
import { Download, Plus, Save } from "lucide-react"
import { useState } from "preact/hooks"
import { backup_accounting_columns } from "./backup_accounting_columns"
import { TotalDisplay } from "./total_display"

// Create a type that extends ReasonCodeInfo with just the fields we need
export interface BackupWithMatching extends ReasonCodeInfo {
  deduction_id: string
  backup_id: string
  reason_code?: ReasonCode
  deduction_reason_code_id?: string
  code_description: string
  retailer_name: string
  product_category: string
  execution_date: string
  retailer_invoice_number: string
}

interface BackupTableWithMatchingProps {
  backup: ReasonCodeInfo[]
  loading: boolean
  deduction_id: string
  deduction: DeductionResponse
}

export const createEmptyBackup = (
  deduction: DeductionResponse,
  remainingAmount?: number
): BackupWithMatching => ({
  id: `manual-${Date.now()}-${Math.random()}`,
  deduction_id: deduction.id,
  backup_id: "",
  name: "",
  description: "",
  expense_account: "",
  actor: "manual",
  code_type: "",
  reason_code: undefined,
  promo_type: "",
  promo_sub_type: "",
  code_description: "",
  amount: remainingAmount !== undefined ? Math.abs(remainingAmount) : Math.abs(deduction.invoice_amount),
  customer_name: displayFormattedDistributor(deduction.source, deduction.original_source),
  retailer_name: deduction.retailer_name || "",
  product_line: "",
  product_category: "",
  execution_date: "",
  retailer_invoice_number: "",
})

export function transformInitialBackup(
  initialBackup: ReasonCodeInfo[] | undefined,
  deduction: DeductionResponse,
  deduction_id: string
): BackupWithMatching[] {
  // TODO(joey): retailer_name can come from a few different places...category, deduction, or backup...in this context
  // If we have reason codes, use those as the initial data
  if (deduction.reason_codes && deduction.reason_codes.length > 0) {
    // @ts-ignore reason_code type mismatch is acceptable here
    return deduction.reason_codes.map(rc => ({
      id: `existing-${rc.deduction_reason_code_id}-${Math.random()}`,
      deduction_id,
      name: rc.name || "",
      description: rc.description || "",
      expense_account: rc.expense_account || "",
      actor: "existing",
      code_type: rc.code_type || "",
      reason_code: rc,
      promo_type: rc.promo_type || "",
      promo_sub_type: rc.promo_sub_type || "",
      amount: Math.abs(rc.amount || 0),
      customer_name: rc.customer_name,
      retailer_name: rc.retailer_name || "",
      code_description: rc.code_description || "",
      product_line: rc.product_line || "",
      deduction_reason_code_id: rc.deduction_reason_code_id,
      backup_id: "",
      product_category: rc.product_category || "",
      execution_date: rc.execution_date || deduction.execution_date || "",
      retailer_invoice_number: rc.retailer_invoice_number || "",
    }))
  } else if (initialBackup && initialBackup.length > 0) {
    return initialBackup.map(b => ({
      ...b,
      id: `backup-${b.id}-${Math.random()}`,
      deduction_id,
      reason_code: undefined,
      customer_name: b.customer_name || displayFormattedDistributor(deduction.source, deduction.original_source),
      retailer_name: deduction.retailer_name || "",
      code_description: "",
      amount: Math.abs(b.amount),
      backup_id: b.id || "",
      product_category: b.product_category || "",
      execution_date: b.execution_date || deduction.execution_date || "",
      retailer_invoice_number: b.retailer_invoice_number || "",
    }))
  } else {
    return [createEmptyBackup(deduction)]
  }
}

export function BackupTableWithMatching({
  backup: initialBackup,
  loading,
  deduction_id,
  deduction,
}: BackupTableWithMatchingProps) {
  // Use the new function in useState
  const [backupData, setBackupData] = useState<BackupWithMatching[]>(() =>
    transformInitialBackup(initialBackup, deduction, deduction_id)
  )

  const [isReadyToSave, setIsReadyToSave] = useState(false)

  const addNewRow = () => {
    const remainingAmount = new Decimal(Math.abs(deduction.invoice_amount)).minus(
      backupData.reduce(
        (sum, row) => sum.plus(new Decimal(Math.abs(row.amount || 0))),
        new Decimal(0)
      )
    )
    setBackupData(prev => [
      ...prev,
      createEmptyBackup(deduction, remainingAmount.toNumber()) as BackupWithMatching,
    ])
  }

  const updateData = (rowIndex: number, columnId: string, value: any) => {
    setBackupData(prev => {
      const newData = prev.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          }
        }
        return row
      })

      // Check if all rows have reason codes immediately after update
      const allRowsMatched = newData.every(row => row.expense_account !== undefined)
      setIsReadyToSave(allRowsMatched)

      return newData
    })
  }

  const handleSubmit = async () => {
    // Check if any data has changed
    const hasChanges = backupData.some(row => {
      if (row.actor === "manual") return true // New rows always count as changes
      if (!row.reason_code) return true // Unmatched rows count as changes

      // Check if any fields have been modified
      const original = deduction.reason_codes?.find(rc => rc.id === row.id)
      if (!original) return true

      return (
        row.amount !== original.amount ||
        row.customer_name !== original.customer_name ||
        row.product_line !== original.product_line ||
        row.promo_type !== original.promo_type ||
        row.promo_sub_type !== original.promo_sub_type ||
        row.reason_code.id !== original.id ||
        row.retailer_name !== original.retailer_name ||
        row.code_description !== original.code_description
      )
    })

    if (!hasChanges) {
      toast({
        title: "No Changes",
        description: "No changes detected to save",
      })
      return
    }

    let errorMessage = ""
    const allRowsMatched = backupData.every(row => row.reason_code)
    const matchedSum = backupData.reduce(
      (sum, row) => sum.plus(new Decimal(row.amount || 0)),
      new Decimal(0)
    )

    const difference = new Decimal(matchedSum.abs()).minus(
      new Decimal(Math.abs(deduction.invoice_amount))
    )
    if (!allRowsMatched) {
      errorMessage = "Please match all rows with reason codes."
    } else if (difference.abs().greaterThan(new Decimal("0.001"))) {
      errorMessage = `The sum of the matched amounts (${matchedSum.toFixed(
        2
      )}) differs from the invoice amount (${Math.abs(deduction.invoice_amount).toFixed(
        2
      )}) by ${difference.abs().toFixed(2)}.`
    }

    if (errorMessage) {
      toast({
        title: "Warning",
        description: errorMessage,
      })
      return
    }

    // Create the payload for each matched row
    const payload = backupData.map(row => ({
      deduction_id,
      reason_code_id: row.reason_code?.id,
      amount: row.amount || 0,
      promo_type: row.promo_type || null,
      promo_sub_type: row.promo_sub_type || null,
      product_description: row.product_line || null,
      customer_name: row.customer_name || "",
      retailer_name: row.retailer_name || null,
      code_description: row.code_description || null,
      deduction_reason_code_id: row.deduction_reason_code_id || null,
      product_category: row.product_category || null,
      execution_date: row.execution_date || null,
      retailer_invoice_number: row.retailer_invoice_number || null,
    }))

    const res = await api_fetch("/split_deductions", {
      method: "POST",
      body: payload,
    })

    if (!res.ok) {
      toast({
        title: "Error",
        description: "Failed to save accounting codes",
      })
      return
    }

    toast({
      title: "Success",
      description: `Saved ${backupData.length} accounting codes`,
    })

    // Trigger a global refresh
    StatusFlag.set(flag => !flag)
    setIsReadyToSave(false)
  }

  const handleDeleteRows = (rowIds: string[]) => {
    // First verify we have valid data
    if (!backupData || backupData.length === 0) {
      console.warn("No backup data to delete from")
      return
    }

    // Create new array with filtered data
    const newData = backupData.filter(row => !rowIds.includes(row.id))

    // Update state with new data
    setBackupData(newData)

    // Show toast after confirming the deletion was successful
    toast({
      title: "Success",
      description: `Removed ${rowIds.length} row${rowIds.length > 1 ? "s" : ""}`,
    })
  }

  const downloadBackupCsv = () => {
    const data = backupData
    // @ts-ignore
    const csvString = convertBackupToCsv(collectDownloadData(data.map(d => ({ ...d, deduction_id }))))
    downloadCsv(csvString, `deduction-${deduction?.invoice_number || deduction_id}`)
  }

  const calculateTotal = () => {
    return backupData.reduce(
      (sum, row) => sum.plus(new Decimal(row.amount || 0)),
      new Decimal(0)
    ).toNumber()
  }

  const toolbar = (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <div className="flex gap-2">
          <Button onClick={addNewRow} variant="outline" className="h-8">
            <Plus className="h-4 w-4 mr-2" />
            Add Row
          </Button>
          <Button onClick={handleSubmit} variant="tertiary" className="h-8" disabled={!isReadyToSave}>
            <Save className="h-4 w-4 mr-2" />
            Save Matches
          </Button>
          <Button onClick={downloadBackupCsv} variant="outline" className="h-8">
            <Download className="h-4 w-4 mr-2" />
            Download CSV
          </Button>
        </div>
        <TotalDisplay 
          currentTotal={calculateTotal()} 
          targetAmount={deduction.invoice_amount} 
        />
      </div>
    </div>
  )

  return (
    <div className="flex flex-col">
      <BackupTable<BackupWithMatching, unknown>
        loading={loading}
        columns={backup_accounting_columns}
        data={backupData}
        meta={{
          updateData,
          onDeleteRows: handleDeleteRows,
        }}
        toolbar={toolbar}
      />
    </div>
  )
}

if (import.meta.vitest) {
  describe("BackupTableWithMatching", () => {
    // Add type for mock data
    type MockReasonCode = {
      deduction_reason_code_id: string
      name?: string | null
      description?: string | null
      expense_account?: string
      code_type?: string
      promo_type?: string
      promo_sub_type?: string
      amount?: number
      customer_name?: string
      product_line?: string
      retailer_name?: string
      code_description?: string
      id: string
      org_id?: string // Add required field from ReasonCode type
    }

    describe("amount calculations", () => {
      it("should handle precise decimal calculations", () => {
        const amounts = [new Decimal("100.11"), new Decimal("200.22"), new Decimal("300.33")]
        const matchedSum = amounts.reduce((sum, amount) => sum.plus(amount), new Decimal(0))
        const invoiceAmount = new Decimal("600.66")

        const difference = matchedSum.minus(invoiceAmount).abs()
        expect(difference.lessThan(new Decimal("0.001"))).toBe(true)
      })

      it("should detect invalid amount matches", () => {
        const amounts = [
          new Decimal("100.11"),
          new Decimal("200.22"),
          new Decimal("300.34"), // Slightly off
        ]
        const matchedSum = amounts.reduce((sum, amount) => sum.plus(amount), new Decimal(0))
        const invoiceAmount = new Decimal("600.66")

        const difference = matchedSum.minus(invoiceAmount).abs()
        expect(difference.greaterThan(new Decimal("0.001"))).toBe(true)
      })

      it("should handle negative amounts", () => {
        const matchedSum = new Decimal("-600.66")
        const invoiceAmount = new Decimal("-600.66")

        const difference = matchedSum.abs().minus(invoiceAmount.abs()).abs()
        expect(difference.lessThan(new Decimal("0.001"))).toBe(true)
      })

      it("should handle common floating point edge cases", () => {
        const amounts = [new Decimal("0.1"), new Decimal("0.2"), new Decimal("0.3")]
        const matchedSum = amounts.reduce((sum, amount) => sum.plus(amount), new Decimal(0))
        const expected = new Decimal("0.6")

        const difference = matchedSum.minus(expected).abs()
        expect(difference.lessThan(new Decimal("0.001"))).toBe(true)
      })
    })

    describe("createEmptyBackup", () => {
      it("should create backup with correct defaults", () => {
        const mockDeduction: DeductionResponse = {
          id: "test-123",
          invoice_amount: 500.0,
          source: "Test Distributor",
        } as DeductionResponse

        const backup = createEmptyBackup(mockDeduction)

        expect(backup).toMatchObject({
          deduction_id: "test-123",
          backup_id: "",
          name: "",
          description: "",
          expense_account: "",
          actor: "manual",
          code_type: "",
          reason_code: undefined,
          promo_type: "",
          promo_sub_type: "",
          amount: 500.0,
          customer_name: "Test Distributor",
          product_line: "",
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
        // Check that ID follows expected pattern
        expect(backup.id).toMatch(/^manual-\d+-\d+(\.\d+)?$/)
      })

      it("should handle negative invoice amounts", () => {
        const mockDeduction: DeductionResponse = {
          id: "test-123",
          invoice_amount: -750.5,
          source: "Test Distributor",
        } as DeductionResponse

        const backup = createEmptyBackup(mockDeduction)
        expect(backup.amount).toBe(750.5) // Should be positive
      })

      it("should properly format distributor name", () => {
        const mockDeduction: DeductionResponse = {
          id: "test-123",
          invoice_amount: 100,
          source: "TEST_DISTRIBUTOR_NAME",
        } as DeductionResponse

        const backup = createEmptyBackup(mockDeduction)
        expect(backup.customer_name).toBe("Test Distributor Name") // Should be formatted
      })

      it("should create backup with retailer name and code description", () => {
        const mockDeduction: DeductionResponse = {
          id: "test-123",
          invoice_amount: 500.0,
          source: "Test Distributor",
          retailer_name: "Test Retailer",
        } as DeductionResponse

        const backup = createEmptyBackup(mockDeduction)

        expect(backup).toMatchObject({
          deduction_id: "test-123",
          backup_id: "",
          name: "",
          description: "",
          expense_account: "",
          actor: "manual",
          code_type: "",
          reason_code: undefined,
          promo_type: "",
          promo_sub_type: "",
          amount: 500.0,
          customer_name: "Test Distributor",
          product_line: "",
          retailer_name: "Test Retailer",
          code_description: "",
          product_category: "",
          retailer_invoice_number: "",
        })
      })

      it("should handle missing retailer name", () => {
        const mockDeduction: DeductionResponse = {
          id: "test-123",
          invoice_amount: 500.0,
          source: "Test Distributor",
          retailer_name: undefined,
        } as unknown as DeductionResponse

        const backup = createEmptyBackup(mockDeduction)
        expect(backup.retailer_name).toBe("")
      })
    })

    describe("data transformations", () => {
      const mockDeductionId = "deduction-123"
      const mockReasonCode: MockReasonCode = {
        deduction_reason_code_id: "rc-123",
        name: "Test Code",
        description: "Test Description",
        expense_account: "1234",
        code_type: "TEST",
        promo_type: "PROMO",
        promo_sub_type: "SUB",
        amount: 100,
        customer_name: "Customer",
        product_line: "Product",
        id: "reason-123",
        org_id: "org-123", // Add required field
      }

      it("should transform reason codes to backup data", () => {
        const deduction = {
          reason_codes: [mockReasonCode],
        } as unknown as DeductionResponse

        const backup = deduction.reason_codes?.map(rc => ({
          id: `existing-${rc.deduction_reason_code_id}-${Math.random()}`,
          deduction_id: mockDeductionId,
          name: rc.name || "",
          description: rc.description || "",
          expense_account: rc.expense_account || "",
          actor: "existing",
          code_type: rc.code_type || "",
          reason_code: rc as unknown as ReasonCode,
          promo_type: rc.promo_type || "",
          promo_sub_type: rc.promo_sub_type || "",
          amount: rc.amount || 0,
          customer_name: rc.customer_name || "",
          product_line: rc.product_line || "",
          deduction_reason_code_id: rc.deduction_reason_code_id,
          backup_id: rc.id,
          product_category: rc.product_category || "",
          execution_date: rc.execution_date || "",
          retailer_invoice_number: rc.retailer_invoice_number || "",
        }))

        expect(backup?.[0]).toMatchObject({
          deduction_id: mockDeductionId,
          name: "Test Code",
          description: "Test Description",
          expense_account: "1234",
          actor: "existing",
          code_type: "TEST",
          promo_type: "PROMO",
          promo_sub_type: "SUB",
          amount: 100,
          customer_name: "Customer",
          product_line: "Product",
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
        expect(backup?.[0].id).toMatch(/^existing-rc-123-\d+(\.\d+)?$/)
      })

      it("should handle empty or undefined fields", () => {
        const emptyReasonCode: MockReasonCode = {
          ...mockReasonCode,
          name: undefined,
          description: null,
          expense_account: "",
        }

        const deduction = {
          reason_codes: [emptyReasonCode],
        } as unknown as DeductionResponse

        const backup = deduction.reason_codes?.map(rc => ({
          id: `existing-${rc.deduction_reason_code_id}-${Math.random()}`,
          deduction_id: mockDeductionId,
          name: rc.name || "",
          description: rc.description || "",
          expense_account: rc.expense_account || "",
          actor: "existing",
          code_type: rc.code_type || "",
          reason_code: rc as unknown as ReasonCode,
          promo_type: rc.promo_type || "",
          promo_sub_type: rc.promo_sub_type || "",
          amount: rc.amount || 0,
          customer_name: rc.customer_name || "",
          product_line: rc.product_line || "",
          deduction_reason_code_id: rc.deduction_reason_code_id,
          backup_id: rc.id,
          product_category: rc.product_category || "",
          execution_date: rc.execution_date || "",
          retailer_invoice_number: rc.retailer_invoice_number || "",
        }))

        expect(backup?.[0]).toMatchObject({
          name: "",
          description: "",
          expense_account: "",
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
      })

      it("should transform reason codes with retailer and code description", () => {
        const mockReasonCodeWithRetailer: MockReasonCode = {
          ...mockReasonCode,
          retailer_name: "Specific Retailer",
          code_description: "Special Code Description",
        }

        const deduction = {
          reason_codes: [mockReasonCodeWithRetailer],
          retailer_name: "Default Retailer",
        } as unknown as DeductionResponse

        const backup = transformInitialBackup(
          [mockReasonCodeWithRetailer] as unknown as ReasonCodeInfo[],
          deduction,
          mockDeductionId
        )

        expect(backup[0]).toMatchObject({
          retailer_name: "Specific Retailer",
          code_description: "Special Code Description",
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
      })

      it("should use deduction retailer name as fallback for backup data", () => {
        const initialBackup = [
          {
            id: "backup-1",
            name: "Test Backup",
            amount: 100,
          },
        ] as unknown as ReasonCodeInfo[]

        const deduction = {
          id: "test-123",
          retailer_name: "Fallback Retailer",
          source: "TEST_DISTRIBUTOR",
        } as unknown as DeductionResponse

        const backup = transformInitialBackup(initialBackup, deduction, deduction.id)

        expect(backup[0]).toMatchObject({
          retailer_name: "Fallback Retailer",
          code_description: "",
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
      })

      it("should use default values when no retailer name is provided", () => {
        const initialBackup = [
          {
            id: "backup-1",
            name: "Test Backup",
            amount: 100,
          },
        ] as unknown as ReasonCodeInfo[]

        const deduction = {
          id: "test-123",
          source: "TEST_DISTRIBUTOR",
        } as unknown as DeductionResponse

        const backup = transformInitialBackup(initialBackup, deduction, deduction.id)

        expect(backup[0]).toMatchObject({
          retailer_name: "",
          code_description: "",
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
      })
    })

    describe("initial backup data transformation", () => {
      it("should transform backup data when no reason codes exist", () => {
        const mockDeduction: DeductionResponse = {
          id: "test-123",
          invoice_amount: -1000.0,
          source: "TEST_DISTRIBUTOR",
        } as DeductionResponse

        const initialBackup = [
          {
            id: "backup-1",
            name: "Test Backup",
            description: "Test Description",
            expense_account: "1234",
            amount: 600,
            customer_name: "Custom Customer",
          },
          {
            id: "backup-2",
            name: "Test Backup 2",
            description: "Test Description 2",
            expense_account: "5678",
            amount: 400,
            customer_name: undefined,
          },
        ] as unknown as ReasonCodeInfo[]

        const backupData = transformInitialBackup(initialBackup, mockDeduction, mockDeduction.id)

        expect(backupData).toHaveLength(2)
        expect(backupData[0]).toMatchObject({
          id: expect.stringMatching(/^backup-backup-1-\d+(\.\d+)?$/),
          deduction_id: "test-123",
          name: "Test Backup",
          description: "Test Description",
          expense_account: "1234",
          amount: 600,
          customer_name: "Custom Customer",
          backup_id: "backup-1",
          reason_code: undefined,
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
        expect(backupData[1]).toMatchObject({
          id: expect.stringMatching(/^backup-backup-2-\d+(\.\d+)?$/),
          deduction_id: "test-123",
          name: "Test Backup 2",
          description: "Test Description 2",
          expense_account: "5678",
          amount: 400,
          customer_name: "Test Distributor", // Falls back to formatted deduction.source
          backup_id: "backup-2",
          reason_code: undefined,
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
      })

      it("should create single empty backup when no data exists", () => {
        const mockDeduction: DeductionResponse = {
          id: "test-123",
          invoice_amount: -1000.0,
          source: "TEST_DISTRIBUTOR",
        } as DeductionResponse

        const backupData = transformInitialBackup([], mockDeduction, mockDeduction.id)

        expect(backupData).toHaveLength(1)
        expect(backupData[0]).toMatchObject({
          deduction_id: "test-123",
          backup_id: "",
          name: "",
          description: "",
          expense_account: "",
          actor: "manual",
          code_type: "",
          reason_code: undefined,
          promo_type: "",
          promo_sub_type: "",
          amount: 1000.0,
          customer_name: "Test Distributor",
          product_line: "",
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
        expect(backupData[0].id).toMatch(/^manual-\d+-\d+(\.\d+)?$/)
      })

      it("should prioritize reason codes over backup data when both exist", () => {
        const mockDeduction: DeductionResponse = {
          id: "test-123",
          invoice_amount: -1000.0,
          source: "TEST_DISTRIBUTOR",
          reason_codes: [
            {
              deduction_reason_code_id: "rc-1",
              id: "reason-1",
              name: "Reason 1",
              amount: 600,
              customer_name: "Customer 1",
            },
            {
              deduction_reason_code_id: "rc-2",
              id: "reason-2",
              name: "Reason 2",
              amount: 400,
              customer_name: "Customer 2",
            },
          ],
        } as DeductionResponse

        const initialBackup = [
          {
            id: "backup-1",
            name: "Should Not Use This",
            amount: 1000,
          },
        ] as unknown as ReasonCodeInfo[]

        const backupData = transformInitialBackup(initialBackup, mockDeduction, mockDeduction.id)

        expect(backupData).toHaveLength(2)
        expect(backupData[0]).toMatchObject({
          deduction_id: "test-123",
          name: "Reason 1",
          amount: 600,
          customer_name: "Customer 1",
          actor: "existing",
          deduction_reason_code_id: "rc-1",
          backup_id: "",
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
        expect(backupData[1]).toMatchObject({
          deduction_id: "test-123",
          name: "Reason 2",
          amount: 400,
          customer_name: "Customer 2",
          actor: "existing",
          deduction_reason_code_id: "rc-2",
          backup_id: "",
          product_category: "",
          execution_date: "",
          retailer_invoice_number: "",
        })
      })
    })
  })
}
