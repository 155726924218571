import { DeductionResponse } from "@/api/deduction"
import { addDays, differenceInDays } from "date-fns"

interface DisputeConfig {
  maxDays: number
  checkDisputePortalExistence?: string
}

export interface DisputeState {
  canDispute: boolean
  message?: string
}

const DISTRIBUTOR_DISPUTE_CONFIGS: Record<string, DisputeConfig> = {
  cvs: { maxDays: 45, checkDisputePortalExistence: 'CVS Traverse' },
  kehe: { maxDays: 180 * 4 }, // relaxing this for super for now..they want to push back further // 6 months
  unfi: { maxDays: 365 * 2 }, // 1 year
}

export function getDisputeState(deduction: DeductionResponse): DisputeState {
  if (deduction.dispute_id || deduction.is_prepayment) {
    return { canDispute: false }
  }

  const config = DISTRIBUTOR_DISPUTE_CONFIGS[deduction.source.toLowerCase()]
  if (!config) {
    return { canDispute: true }
  }

  const daysSinceInvoice = differenceInDays(new Date(), new Date(deduction.check_date || deduction.invoice_date))
  
  if (daysSinceInvoice > config.maxDays) {
    return {
      canDispute: false,
      message: `Cannot dispute - older than ${config.maxDays} days`
    }
  }

  if (config.checkDisputePortalExistence && !deduction?.original_source?.toLowerCase().includes(config.checkDisputePortalExistence.toLowerCase())) {
    return {
      canDispute: false,
      message: 'Cannot dispute - no dispute portal found'
    }
  }

  return { canDispute: true }
}

export function getCvsDisputeUrl(deduction: DeductionResponse): string {
  return `https://cvs.traversesystems.com/#/entry/dispute?keyNum=${deduction.invoice_number}`
}

if (import.meta.vitest) {
  const { describe, expect, it } = import.meta.vitest

  // todo: let's make the shield / button yellow if outside the time window but don't need to hide it entirely
  describe.skip('dispute_utils', () => {
    describe('getDisputeState', () => {
      // @ts-ignore
      const baseDeduction: DeductionResponse = {
        id: '1',
        invoice_number: '12345',
        source: 'cvs',
        invoice_date: new Date().toISOString(),
        original_source: 'CVS Traverse',
        dispute_id: null,
        is_prepayment: false,
      } as DeductionResponse

      describe('general dispute rules', () => {
        it('should not allow dispute if deduction has dispute_id', () => {
          const deduction = { ...baseDeduction, dispute_id: '123' }
          expect(getDisputeState(deduction)).toEqual({ canDispute: false })
        })

        it('should not allow dispute if deduction is prepayment', () => {
          const deduction = { ...baseDeduction, is_prepayment: true }
          expect(getDisputeState(deduction)).toEqual({ canDispute: false })
        })

        it('should allow dispute for unknown distributors', () => {
          const deduction = { ...baseDeduction, source: 'unknown' }
          expect(getDisputeState(deduction)).toEqual({ canDispute: true })
        })
      })

      describe('CVS dispute rules', () => {
        it('should allow dispute for CVS within 45 days with Traverse portal', () => {
          const deduction = {
            ...baseDeduction,
            source: 'cvs',
            invoice_date: new Date().toISOString(),
            original_source: 'CVS Traverse'
          }
          expect(getDisputeState(deduction)).toEqual({ canDispute: true })
        })

        it('should not allow dispute for CVS older than 45 days', () => {
          const oldDate = addDays(new Date(), -46).toISOString()
          const deduction = {
            ...baseDeduction,
            source: 'cvs',
            invoice_date: oldDate
          }
          expect(getDisputeState(deduction)).toEqual({
            canDispute: false,
            message: 'Cannot dispute - older than 45 days'
          })
        })

        it('should not allow dispute for CVS without Traverse portal', () => {
          const deduction = {
            ...baseDeduction,
            source: 'cvs',
            original_source: 'CVS Other'
          }
          expect(getDisputeState(deduction)).toEqual({
            canDispute: false,
            message: 'Cannot dispute - no dispute portal found'
          })
        })
      })

      describe('KeHE dispute rules', () => {
        it('should allow dispute for KeHE within 180 days', () => {
          const deduction = {
            ...baseDeduction,
            source: 'kehe',
            invoice_date: new Date().toISOString()
          }
          expect(getDisputeState(deduction)).toEqual({ canDispute: true })
        })

        it('should not allow dispute for KeHE older than 180 days', () => {
          const oldDate = addDays(new Date(), -181).toISOString()
          const deduction = {
            ...baseDeduction,
            source: 'kehe',
            invoice_date: oldDate
          }
          expect(getDisputeState(deduction)).toEqual({
            canDispute: false,
            message: 'Cannot dispute - older than 180 days'
          })
        })
      })

      describe('UNFI dispute rules', () => {
        it('should allow dispute for UNFI within 365 days', () => {
          const deduction = {
            ...baseDeduction,
            source: 'unfi',
            invoice_date: new Date().toISOString()
          }
          expect(getDisputeState(deduction)).toEqual({ canDispute: true })
        })

        it('should not allow dispute for UNFI older than 365 days', () => {
          const oldDate = addDays(new Date(), -366).toISOString()
          const deduction = {
            ...baseDeduction,
            source: 'unfi',
            invoice_date: oldDate
          }
          expect(getDisputeState(deduction)).toEqual({
            canDispute: false,
            message: 'Cannot dispute - older than 365 days'
          })
        })
      })
    })

    describe('getCvsDisputeUrl', () => {
      it('should generate correct CVS dispute URL', () => {
        const deduction = {
          invoice_number: '12345'
        } as DeductionResponse

        expect(getCvsDisputeUrl(deduction)).toBe(
          'https://cvs.traversesystems.com/#/entry/dispute?keyNum=12345'
        )
      })
    })
  })
} 