import { api_fetch, DeductionResponse } from "@/api"
import { Navbar } from "@/app/navbar"
import { INVOICE_COLUMNS } from "@/dashboard/sales/sales_columns"
import { StatusFlag } from "@/deductions/status_state"
import { SummaryCards } from "@/deductions/summary_cards"
import { BASE_COLUMNS } from "@/deductions/table/columns"
import { DeductionTable } from "@/deductions/table/data_table"
import { DataTableState } from "@/deductions/table_state.tsx"
import {
  map,
  toISODateString,
  useAsyncEffect,
  useSearchParams,
} from "@/utils"
import { useEffect, useMemo, useState } from "preact/compat"

export function cleanDeductionList(data: DeductionResponse[]): DeductionResponse[] {
  return data.map(d => {
    d.category = d.category ?? "Misc"
    let kv: [string, any][] = Object.entries(d).map(([k, v]) => [k, v ?? ""])
    kv.push(["validated", d.status_value === "validated"])
    return Object.fromEntries(kv) as DeductionResponse
  })
}

export function Deductions() {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<DeductionResponse[]>([])
  const statusFlag = StatusFlag.use(sf => sf!)
  const tableState = DataTableState.use(ts => ts!)
  const [isOpen, setIsOpen] = useState(false)

  // Get the initial search value from URL query params and decode it
  const { search: encodedSearch } = useSearchParams<{ search?: string }>()
  const urlSearch = encodedSearch ? decodeURIComponent(encodedSearch) : undefined

  // Use useEffect to set the initial search from URL if it exists
  useEffect(() => {
    if (urlSearch && urlSearch !== tableState.search) {
      DataTableState.set(s => ({ ...s, search: urlSearch }))
    }
  }, [urlSearch])

  const { startDate, endDate, distributor, transactionTypes } = tableState

  useAsyncEffect(async () => {
    const res = await api_fetch<DeductionResponse[]>("/deduction/list", {
      method: "POST",
      body: {
        start_date: map(startDate, toISODateString),
        end_date: map(endDate, toISODateString),
        distributor,
        transaction_types: transactionTypes,
      },
    })
    setLoading(false)
    if (!res.ok) {
      throw new Error("Failed to fetch data")
    }
    let data = cleanDeductionList(res.value.data)
    setData(data)
  }, [startDate, endDate, statusFlag, distributor, transactionTypes])

  // Add effect to handle opening collapsible when filters are present
  useEffect(() => {
    const hasFilters = tableState.columnFilters && tableState.columnFilters.length > 0
    if (hasFilters && !isOpen) {
      setIsOpen(true)
    } else if (!hasFilters && isOpen) {
      setIsOpen(false)
    }
  }, [tableState.columnFilters])

  // Determine which columns to use
  const columns = useMemo(() => {
    if (tableState.transactionTypes?.length === 1 && tableState.transactionTypes[0] === "invoice") {
      return INVOICE_COLUMNS
    }
    return BASE_COLUMNS
  }, [tableState.transactionTypes])

  return (
    <div>
      <Navbar/>
      <SummaryCards />
      <div className="mx-auto">
        <DeductionTable loading={loading} columns={columns} data={data} />
      </div>
    </div>
  )
}
