import { Button } from "@/components/ui/button.tsx"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx"
import { Column } from "@tanstack/react-table"
import { ArrowDownIcon, ArrowUpIcon, ChevronsUpDownIcon, XCircle } from "lucide-react"

import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { DataTableState, INITIAL_TABLE_STATE } from "@/deductions/table_state"
import { ActiveFilterIcon } from "@/global_filter"
import { classes } from "@/utils"
import { capitalCase } from "change-case"
import { useState } from "preact/compat"
import { SearchInput } from "./debounced_input"

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  title: string
  choices?: string[]
  onSelectChoice?: (choice: string) => void
}

export function ColumnHeader<TData, TValue>({
  column,
  title,
  className,
  choices,
  onSelectChoice,
}: DataTableColumnHeaderProps<TData, TValue>) {
  const [open, setOpen] = useState(false)

  if (!column.getCanSort()) {
    return <div className={classes(className)}>{title}</div>
  }

  const clearAllFilters = () => {
    DataTableState.set(state => ({
      ...state,
      columnFilters: INITIAL_TABLE_STATE.columnFilters
    }))
  }

  const handleFilterChange = (value: string | number) => {
    column.setFilterValue(String(value))
  }

  const getColumnIcon = () => {
    if (column.getIsFiltered()) return <ActiveFilterIcon />
    const sort = column.getIsSorted()
    return sort === "asc" 
      ? <ArrowUpIcon className="h-4 w-4" />
      : sort === "desc"
        ? <ArrowDownIcon className="h-4 w-4" />
        : <ChevronsUpDownIcon className="ml-2 h-4 w-4" />
  }

  const renderFilter = () => {
    if (choices) {
      return (
        <Select onValueChange={onSelectChoice} defaultValue={column.getFilterValue() as string ?? "all"}>
          <SelectTrigger>
            <SelectValue placeholder="" />
            <ArrowDownIcon className="h-4 w-4" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem key={0} value="all">&nbsp;</SelectItem>
              {choices.map((choice, idx) => (
                <SelectItem key={idx + 1} value={choice}>
                  {capitalCase(choice)}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      )
    }
    return (
      <SearchInput
        value={column.getFilterValue() as string ?? ""}
        onChange={handleFilterChange}
        className="min-w-96"
        placeholder={`Filter ${title.toLowerCase()}...`}
        autoFocus
      />
    )
  }

  return (
    <div className={classes("flex items-center", className)}>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            // @ts-ignore
            size="sm"
            className="-ml-3 h-8 data-[state=open]:bg-accent"
          >
            <span>{title}</span>
            {getColumnIcon()}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start" onKeyDown={(e) => e.stopPropagation()}>
          <DropdownMenuItem onClick={() => column.toggleSorting(false)}>
            <ArrowUpIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            Asc
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => column.toggleSorting(true)}>
            <ArrowDownIcon className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            Desc
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={clearAllFilters}>
            <XCircle className="mr-2 h-3.5 w-3.5 text-muted-foreground/70" />
            Clear Filter
          </DropdownMenuItem>
          <DropdownMenuItem 
            onClick={(e) => e.preventDefault()}
            onKeyDown={(e) => e.stopPropagation()}
          >
            {renderFilter()}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
