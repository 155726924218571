import { Button } from "@/components/ui/button"
import { isSuperCoffeeUser } from "@/utils"
import { Search } from "lucide-react"

interface GoogleDriveSearchProps {
  orgId?: string
  source?: string
  invoiceNumber?: string
}

export function GoogleDriveSearch({ orgId, source, invoiceNumber }: GoogleDriveSearchProps) {
  if (
    !orgId || 
    !source || 
    !invoiceNumber || 
    !isSuperCoffeeUser(orgId) || 
    ["kehe", "unfi"].includes(source.toLowerCase())
  ) {
    return null
  }

  return (
    <Button
      variant="outline"
      // @ts-ignore
      size="sm"
      asChild
    >
      <a
        href={`https://drive.google.com/drive/search?q=${encodeURIComponent(invoiceNumber)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-2"
      >
        <Search className="h-4 w-4" />
        Search Google Drive for Backup
      </a>
    </Button>
  )
} 