import { Card } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import { DeductionFile, handleFileUpload, SimpleFileUploader } from "@/deductions/detail/files"
import { GoogleDriveSearch } from "@/deductions/detail/google_drive_search"
import { formatDistanceToNow } from "date-fns"
import { Eye, FilesIcon, PaperclipIcon } from "lucide-react"

type FilesProps = {
  files: DeductionFile[]
  deductionId: string
  onFileSelect: (file: DeductionFile) => void
  orgId?: string
  source?: string
  invoiceNumber?: string
}

export function Files({ files, deductionId, onFileSelect, orgId, source, invoiceNumber}: FilesProps) {

  return (
    <Card className="h-48 mt-1.5 overflow-y-auto">
      <div className="flex justify-between items-center m-4">
        <h2 className="flex text-xl">
          <FilesIcon className="h-6 w-6" />
          <span className="ml-2">Files</span>
        </h2>
        <div className="flex gap-2">
          <GoogleDriveSearch orgId={orgId} source={source} invoiceNumber={invoiceNumber} />
          <SimpleFileUploader 
            onFileUpload={async uploadedFiles => {
              const newFiles = await handleFileUpload(deductionId, uploadedFiles)
              if (newFiles.length > 0) {
                onFileSelect(newFiles[newFiles.length - 1]) // Select the last uploaded file
              }
            }} 
          />
        </div>
      </div>
      <div className="px-4 w-full flex justify-center">
        <Separator className="w-1/2" />
      </div>
      <div className="flex flex-col gap-4 mt-4 mb-4">
        {files.map((file, i) => (
          <div
            key={i}
            className="flex mt-2 gap-2 items-center ml-5 hover:cursor-pointer pr-5"
            onClick={() => onFileSelect(file)}>
            <div className="flex-shrink-0">
              <div className="inline-flex items-center justify-center border p-2 rounded-3xl">
                <PaperclipIcon className="text-gray-800 h-5 w-5" />
              </div>
            </div>
            <div className="flex-grow min-w-0">
              <div className="text-lg truncate">{file.display_name}</div>
              <div className="text-gray-500 truncate">{`Uploaded${
                file.actor_name ? ` by ${file.actor_name}` : ""
              } ${formatDistanceToNow(new Date(file.date), { addSuffix: true })}`}</div>
            </div>
            <div className="flex-shrink-0">
              <Eye className="text-gray-800 h-6 w-6" />
            </div>
          </div>
        ))}
      </div>
    </Card>
  )
}
